import { Alert, Box, Button, Snackbar, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { AppPanelHeader } from "../../Common/components/AppPanelHeader";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Common/context/ContextContainer";

export function SigninForm({ toggleForm }: { toggleForm: () => void }) {
  const navigate = useNavigate();
  const { auth } = useContext(AppContext);
  const [signinError, setSigninError] = useState<{
    message: string;
  } | null>(null);
  const [signinForm, setSigninForm] = useState<{
    username: string | null;
    password: string | null;
    passwordConfirmation: string | null;
  }>({ username: null, password: null, passwordConfirmation: null });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (null === signinForm.username || null === signinForm.password) {
      return;
    }

    auth
      .signin(signinForm.username, signinForm.password)
      .then(() => toggleForm())
      .catch((err) => {
        setSigninError({ message: err.description });
      });
  }

  function resetError() {
    setSigninError(null);
  }

  function handleFieldChange(e: { target: { name: string; value: string } }) {
    setSigninForm({ ...signinForm, [e.target.name]: e.target.value });
  }

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <AppPanelHeader title={"Inscription"} onClose={() => navigate("/")} />
        <Box display={"flex"} flexDirection={"column"} gap={2} pt={2}>
          <TextField
            name="username"
            label="Email"
            type="email"
            required
            focused
            onChange={handleFieldChange}
          />
          <TextField
            name="password"
            label="Mot de passe"
            type="password"
            required
            onChange={handleFieldChange}
          />
          <TextField
            name="passwordConfirmation"
            label="Confirmation du mot de passe"
            type="password"
            required
            onChange={handleFieldChange}
          />
          <Button
            variant={"contained"}
            type="submit"
            disabled={
              null === signinForm.username ||
              null === signinForm.password ||
              signinForm.password !== signinForm.passwordConfirmation
            }
          >
            M&apos;inscrire
          </Button>
        </Box>
      </form>
      <Snackbar
        open={null !== signinError}
        autoHideDuration={6000}
        onClose={resetError}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={resetError} severity="error" sx={{ width: "100%" }}>
          {signinError?.message}
        </Alert>
      </Snackbar>
    </>
  );
}
