import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Common/context/ContextContainer";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { AppPanelHeader } from "../../Common/components/AppPanelHeader";
import { UserType } from "../../Common/model/types";

export function LoginForm(props: {
  from: string;
  setFormToShow: Dispatch<
    SetStateAction<"login" | "signin" | "reinitPassword" | "newPassword">
  >;
}): React.ReactElement {
  const navigate = useNavigate();
  const { auth } = useContext(AppContext);
  const [loginError, setLoginError] = useState<{
    message: string;
  } | null>(null);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get("username") as string;
    const password = formData.get("password") as string;

    auth.login(
      username,
      password,
      (user: UserType) => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(props.from, { replace: true });

        return user;
      },
      (e: { description: string }) => {
        setLoginError({ message: e.description });
      }
    );
  }

  function resetError() {
    setLoginError(null);
  }

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <AppPanelHeader title={"Connexion"} onClose={() => navigate("/")} />
        <Box display={"flex"} flexDirection={"column"} gap={2} pt={2}>
          <TextField
            name="username"
            label="Email"
            type="email"
            required
            focused
          />
          <TextField
            name="password"
            label="Mot de passe"
            type="password"
            autoComplete="current-password"
            required
          />
          <Button variant={"contained"} type="submit">
            Me connecter
          </Button>
        </Box>
      </form>
      <Box my={4}>
        <Typography
          variant={"body2"}
          role={"button"}
          align={"center"}
          style={{ textDecoration: "underline" }}
          onClick={() => props.setFormToShow("reinitPassword")}
        >
          Mot de passe oublié ?
        </Typography>
      </Box>
      <Snackbar
        open={null !== loginError}
        autoHideDuration={6000}
        onClose={resetError}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={resetError} severity="error" sx={{ width: "100%" }}>
          {loginError?.message}
        </Alert>
      </Snackbar>
    </>
  );
}
