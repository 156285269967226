import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Drawer, Typography } from "@mui/material";
import { OfficeThumbnail } from "./OfficeThumbnail";
import { useCompany } from "../../Common/domain/hooks/useCompany";
import { CompanyDetail } from "./CompanyDetail";
import { SelectedOfficePanel } from "./SelectedOfficePanel";
import { BureauType } from "../../Common/model/types";
import { AppIcon } from "../../Common/components/AppIcon";
import { useNavigate } from "react-router-dom";

export function Details(): React.ReactElement {
  const history = useNavigate();
  const [company] = useCompany();
  const [selectedOffice, setSelectedOffice] = useState<BureauType | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setOpen(true), 50);
  }, []);

  if (null === company) {
    return <></>;
  }

  return (
    <>
      <Drawer
        anchor={"right"}
        open={open}
        PaperProps={{ style: { width: "100vw", height: "calc(100% - 81px)" } }}
        disablePortal
        ModalProps={{
          keepMounted: true,
          disableEnforceFocus: true,
          disableAutoFocus: true,
          style: {
            pointerEvents: "all",
          },
        }}
      >
        <Box style={{ position: "relative", height: "100%" }}>
          <Button
            variant={"contained"}
            color={"secondary"}
            style={{ position: "absolute", top: 40, left: 16 }}
            onClick={() => history(`/home`)}
          >
            <Box py={0.5} display={"flex"} alignItems={"center"}>
              <AppIcon name={"arrow_back"} filled={false} />
            </Box>
          </Button>
          <CompanyDetail company={company} />
          {0 < company.entreprise.bureaux.length && (
            <Box p={2} pb={1}>
              <Typography variant={"h2"}>Notre espace recommandé</Typography>
              <OfficeThumbnail
                office={company.entreprise.bureaux[0]}
                selectOffice={() =>
                  setSelectedOffice(company.entreprise.bureaux[0])
                }
              />
            </Box>
          )}
          {1 < company.entreprise.bureaux.length && (
            <>
              <Divider />

              <Box p={2} pb={1}>
                <Typography variant={"h2"}>Nos autres espaces</Typography>
                {company.entreprise.bureaux.map(
                  (office: BureauType, index: number) => {
                    if (0 === index) {
                      return <React.Fragment key={index} />;
                    }
                    return (
                      <OfficeThumbnail
                        key={index}
                        office={office}
                        selectOffice={() => setSelectedOffice(office)}
                      />
                    );
                  }
                )}
              </Box>
            </>
          )}
          <SelectedOfficePanel
            selectedOffice={selectedOffice}
            setSelectedOffice={setSelectedOffice}
          />
        </Box>
      </Drawer>
    </>
  );
}
