import * as React from "react";
import { Box, Typography } from "@mui/material";

export function AppFilterButton({
  label,
  isActive,
  onClick,
}: {
  label: string;
  isActive: boolean;
  onClick: () => void;
}) {
  return (
    <Box
      role={"button"}
      onClick={onClick}
      sx={(theme) => ({
        background: isActive
          ? theme.palette.secondary.light
          : theme.palette.common.white,
        color: isActive
          ? theme.palette.secondary.main
          : theme.palette.common.black,
        borderRadius: "16px",
        boxSizing: "border-box",
        cursor: "pointer",
        minHeight: 4,
        padding: "6px 16px",
        textAlign: "center",
        whiteSpace: "pre",
      })}
    >
      <Typography variant={"body2"}>{label}</Typography>
    </Box>
  );
}
