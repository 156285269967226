import * as React from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { AppIcon } from "../../Common/components/AppIcon";
import { AppCurrencyBadge } from "../../Common/components/AppCurrencyBadge";
import { Link as RouterLink } from "react-router-dom";
import { uiHelper } from "../../Common/domain/helpers/uiHelper";
import { MyBookingType } from "../../Common/model/types";
import { mapHelper } from "../../Common/domain/helpers/mapHelper";
import { useContext } from "react";
import { AppContext } from "../../Common/context/ContextContainer";

export function NextBookingThumbnail({
  nextBooking,
}: {
  nextBooking?: MyBookingType | null;
}) {
  const { mapInfos } = useContext(AppContext);

  if (!nextBooking) {
    return <></>;
  }

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} gap={2} mb={2}>
        <Typography variant={"h2"}>Prochaine réservation</Typography>
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={0.5}
          color={"secondary.main"}
          bgcolor={"secondary.light"}
          px={1}
          py={0.25}
          borderRadius={4}
        >
          <AppIcon name={"schedule"} size={16} filled={false} />
          <Typography
            variant={"body2"}
            color={"inherit"}
            sx={{ lineHeight: "20px" }}
          >
            {uiHelper.formatHour(nextBooking.heure)}
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} gap={2}>
        <picture
          style={{
            height: 80,
            width: 120,
            aspectRatio: "120/80",
            display: "flex",
          }}
        >
          <img
            src={
              "" !== nextBooking.bureau.url360
                ? nextBooking.bureau.url360
                : nextBooking.bureau.url240
            }
            alt="Photo à remplacer"
            style={{
              maxWidth: "100%",
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </picture>
        <Box>
          <Typography overflow={"hidden"}>
            {nextBooking.entreprise.nom}
          </Typography>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Typography variant={"caption"} color={"text.secondary"}>
              Bureau {nextBooking.bureau.type}
            </Typography>
            <Box display={"flex"} alignItems={"center"} gap={0.5}>
              <Typography
                variant={"caption"}
                display={"flex"}
                alignItems={"center"}
              >
                {nextBooking.bureau.tarif.toFixed(1)}
              </Typography>
              <AppCurrencyBadge small />
            </Box>
          </Box>
          <Box display={"flex"} gap={1} mt={1}>
            <Link
              to={`/booking/${nextBooking.numero}`}
              component={RouterLink}
              underline={"none"}
            >
              <Button
                variant={"contained"}
                color={"secondary"}
                size={"small"}
                fullWidth
                style={{ gap: 8 }}
              >
                <AppIcon name={"qr_code"} filled={false} size={16} />
                Afficher
              </Button>
            </Link>
            <Button
              variant={"outlined"}
              size={"small"}
              onClick={() =>
                window.open(
                  mapHelper.getItineraryBetweenUserAndEntreprise(
                    mapInfos.mapCoords,
                    nextBooking?.entreprise
                  )
                )
              }
            >
              Itinéraire
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
