import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CompanyItemResponse } from "../helpers/client";
import * as client from "../helpers/client";

/**
 * Retourne l'objet "company" et les bureaux associés en fonction du param de l'url
 */
export function useCompany(): [CompanyItemResponse | null] {
  const params = useParams();

  const [company, setCompany] = useState<CompanyItemResponse | null>(null);

  useEffect(() => {
    if (!params.id) {
      return;
    }
    async function fetchCompanyData() {
      const data = await client.getOneCompany(params.id as string);
      setCompany(data);
    }

    fetchCompanyData();
  }, [params]);

  return [company];
}
