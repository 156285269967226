import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#F59100",
    },
    secondary: {
      main: "#3278FA",
      light: "rgba(50,120,250,0.15)",
    },
    grey: {
      100: "#E7E7E7",
      200: "#C0C0C0",
      300: "#404040",
    },
    text: {
      primary: "#000",
      secondary: "#C0C0C0",
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    h1: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: "34px",
    },
    h2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "24px",
    },
    h3: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "24px",
    },
    h4: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "24px",
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "24px",
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "20px",
    },
    caption: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "16px",
    },
  },
  components: {
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "#000",
          "&.Mui-selected": {
            color: "#F59100",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontSize: 14,
          lineHeight: "20px",
          minWidth: 32,
          padding: "12px 16px",
          textTransform: "unset",
        },
        sizeSmall: {
          height: 32,
          padding: "6px 12px",
        },
        containedPrimary: {
          color: "#FFF",
          fontSize: 16,
          lineHeight: "22px",
          padding: "10px 16px",
          "&:hover": {
            color: "#FFF",
          },
        },
        containedSecondary: {
          background: "#000",
          color: "#FFF",
          fontSize: 16,
          lineHeight: "22px",
          padding: "10px 16px",
          "&:hover": {
            background: "#000",
            color: "#FFF",
          },
        },
        outlinedPrimary: {
          backgroundColor: "#FFF",
          borderColor: "#000",
          color: "#000",
          "&:hover": {
            backgroundColor: "#FFF",
            borderColor: "#000",
            color: "#000",
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        secondary: {
          backgroundColor: "#000",
          color: "#FFF",
        },
      },
    },
  },
});
