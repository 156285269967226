import * as React from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { AppIcon } from "../Common/components/AppIcon";
import { Link as RouterLink } from "react-router-dom";
import splashLogo from "../assets/images/remote_splash_logo.png";
import upLogo from "../assets/images/remote_up_logo.png";

/**
 * La landing page
 * @constructor
 */
export function Splash() {
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        bgcolor={"common.black"}
        height={"100%"}
        style={{ pointerEvents: "all" }}
      >
        <picture style={{ display: "flex", aspectRatio: "360/336" }}>
          <img
            src={splashLogo}
            alt="remote +"
            style={{ width: "100%", maxWidth: "100%" }}
          />
        </picture>
        <Box p={4} flexGrow={1} display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} justifyContent={"center"} mb={3}>
            <picture style={{ display: "flex", aspectRatio: "1/1", width: 80 }}>
              <img
                src={upLogo}
                alt="logo de up"
                style={{ width: "100%", maxWidth: "100%" }}
              />
            </picture>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Typography align={"center"} color={"common.white"} maxWidth={232}>
              Vous ouvre l&apos;accès à des espaces de travail dans votre
              quartier
            </Typography>
          </Box>
          <Box flexGrow={1} display={"flex"} alignItems={"flex-end"} mt={2}>
            <Link
              to={"/home"}
              component={RouterLink}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <Button variant={"contained"} fullWidth>
                Accéder à Remote +
                <span style={{ position: "absolute", top: 12, right: 12 }}>
                  <AppIcon name={"arrow_forward"} />
                </span>
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
}
