import React, { useContext } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { AppIcon } from "../Common/components/AppIcon";
import companyPlaceholder from "../assets/images/company_placeholder.png";
import { Link as RouterLink } from "react-router-dom";
import { AppCurrencyBadge } from "../Common/components/AppCurrencyBadge";
import { uiHelper } from "../Common/domain/helpers/uiHelper";
import { MyBookingType } from "../Common/model/types";
import { mapHelper } from "../Common/domain/helpers/mapHelper";
import { AppContext } from "../Common/context/ContextContainer";

/**
 * La vignette d'une réservation
 * @constructor
 */
export function BookingThumbnail({
  booking,
}: {
  booking: MyBookingType;
}): React.ReactElement {
  const { mapInfos } = useContext(AppContext);

  return (
    <Box borderTop={1} borderColor={"grey.100"}>
      <Box p={2}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant={"h2"}>
            {uiHelper.formatDate(new Date(booking.arriveeUTC), true)}
          </Typography>
          <Box
            bgcolor={"secondary.light"}
            borderRadius={4}
            color={"secondary.main"}
            p={0.5}
            gap={0.5}
            display={"flex"}
            alignItems={"center"}
          >
            <AppIcon
              name={"schedule"}
              filled={false}
              color={"inherit"}
              size={16}
            />
            <Typography variant={"body2"} color={"secondary.main"}>
              {uiHelper.formatHour(booking.heure)} - 18h00
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} gap={2} mt={2}>
          <picture style={{ display: "flex", height: 80, width: 120 }}>
            <img src={companyPlaceholder} alt="" />
          </picture>
          <Box>
            <Typography variant={"body1"}>{booking.entreprise?.nom}</Typography>
            <Typography
              variant={"caption"}
              color={"text.secondary"}
              display={"flex"}
              alignItems={"center"}
            >
              Bureau {booking.bureau?.type} {booking.bureau?.tarif.toFixed(1)}
              &nbsp;
              <AppCurrencyBadge small />
            </Typography>
            <Box display={"flex"} alignItems={"center"} gap={1} mt={1}>
              <Link
                to={`/booking/${booking.numero}`}
                component={RouterLink}
                underline={"none"}
              >
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  size={"small"}
                  style={{ gap: 8 }}
                >
                  <AppIcon name={"qr_code"} filled={false} size={16} />
                  Afficher
                </Button>
              </Link>
              <Button
                variant={"outlined"}
                size={"small"}
                onClick={() =>
                  window.open(
                    mapHelper.getItineraryBetweenUserAndEntreprise(
                      mapInfos.mapCoords,
                      booking.entreprise
                    )
                  )
                }
              >
                Itinéraire
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
