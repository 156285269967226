import * as apiClient from "../Common/apiClient";
import {
  HydraCollectionResponse,
  HydraItemResponse,
} from "../../lib/Hydra/hydra";
import { LoginResponseType, MyBookingType } from "../Common/model/types";

export async function login(
  username: string,
  password: string
): Promise<LoginResponseType> {
  return await apiClient.post<LoginResponseType>("/login", {
    username,
    password,
  });
}

export async function reinitPassword(email: string): Promise<void> {
  return await apiClient.post<void>("/account/password/reset", {
    email,
  });
}

export async function newPassword(
  email: string,
  token: string,
  password: string
): Promise<void> {
  return await apiClient.post<void>("/account/password/change", {
    email,
    token,
    password,
  });
}

export async function signin(
  email: string,
  plainPassword: string
): Promise<LoginResponseType> {
  return await apiClient.post<LoginResponseType>("/users", {
    email,
    plainPassword,
  });
}

export interface MyBookingsItemResponse
  extends HydraItemResponse,
    MyBookingType {}

export interface MyBookingsCollectionResponse extends HydraCollectionResponse {
  "hydra:member": MyBookingsItemResponse[];
}

export async function getMyBookings(): Promise<MyBookingsCollectionResponse> {
  return apiClient.get<MyBookingsCollectionResponse>("/bookings/my");
}

export async function cancel(id: string): Promise<MyBookingsItemResponse> {
  return apiClient.post(`/bookings/${id}/cancel`, {});
}
