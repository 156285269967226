import { SERVICES } from "./serviceshelper";
import { SearchQueryType } from "../../model/types";

export const DATE_FIELDS_OPTIONS: {
  [key: string]: string;
} = {
  today: "Maintenant",
  tomorrow: "Demain",
};

export const DISTANCE_FIELDS_OPTIONS: { key: number; value: string }[] = [
  { key: 0.66, value: "10 min" },
  { key: 1.333, value: "20 min" },
  { key: 2, value: "30 min" },
  { key: 150, value: "plus" },
];

export const NOTE_FIELDS_OPTIONS: {
  [key: string]: string;
} = {
  all: "Toutes",
  "3.5": "+3.5",
  "4.0": "+4",
  "4.5": "+4.5",
};

export function formValuesToSearchQuery(searchQuery: SearchQueryType) {
  const query = { ...searchQuery };
  query.latitude = Number(searchQuery.coordinates?.split(",")[0]);
  query.longitude = Number(searchQuery.coordinates?.split(",")[1]);
  if (null === query.radius) {
    query.radius = 150;
  }
  if (searchQuery.cost) {
    query.maxPrice = searchQuery.cost[1];
  }

  /**
   * Le formulaire stocke des labels pour les services
   * Pour la requête on doit envoyer un string contenant les IDs
   */
  if (searchQuery.conveniences) {
    const services: number[] = [];
    searchQuery.conveniences.forEach((serviceLabel) => {
      const serviceIdFromLabel = Object.entries(SERVICES).find(
        ([, service]) => service.label === serviceLabel
      );

      if (undefined !== serviceIdFromLabel) {
        services.push(Number(serviceIdFromLabel[0]));
      }
    });
    query.services = services.join(",");
  }

  delete query.coordinates;
  delete query.conveniences;
  delete query.cost;

  return query;
}
