import React from "react";
import { Box, Button, Typography } from "@mui/material";
import companyPlaceholder from "../../assets/images/company_placeholder.png";
import { ServicesList } from "./ServicesList";
import { AppCurrencyBadge } from "../../Common/components/AppCurrencyBadge";
import { BureauType } from "../../Common/model/types";

export function OfficeThumbnail({
  office,
  selectOffice,
}: {
  office: BureauType;
  selectOffice: () => void;
}): React.ReactElement {
  return (
    <Box pt={2} pb={1} display={"flex"} alignItems={"center"} gap={2}>
      <Box flexGrow={1}>
        <Typography variant={"body1"}>
          {office["@type"]} {office.type}
        </Typography>
        <Typography variant={"caption"} color={"text.secondary"}>
          {office.caracteristiques}
        </Typography>

        <ServicesList services={office.services} />

        <Box display={"flex"} alignItems={"center"} gap={2} pt={1}>
          <Button
            variant={"contained"}
            color={"secondary"}
            size={"small"}
            onClick={selectOffice}
          >
            Réserver
          </Button>
          <Box display={"flex"} alignItems={"center"} gap={0.5}>
            <Typography variant={"body2"}>{office.tarif.toFixed(1)}</Typography>
            <AppCurrencyBadge small />
          </Box>
        </Box>
      </Box>
      <Box
        component={"picture"}
        sx={(theme) => ({
          aspectRatio: "1/1",
          display: "flex",
          width: 80,
          background: theme.palette.grey[100],
        })}
      >
        <img
          src={"" !== office.url ? office.url : companyPlaceholder}
          alt={`${office["@type"]} ${office.type}`}
          style={{ height: "100%", objectFit: "cover", width: "100%" }}
        />
      </Box>
    </Box>
  );
}
