import React, { useContext, useState } from "react";
import { AppContext } from "../context/ContextContainer";
import { useNavigate } from "react-router-dom";
import { Box, Button, Modal, Typography } from "@mui/material";
import { uiHelper } from "../domain/helpers/uiHelper";

export function AppUserCredentials() {
  const { auth } = useContext(AppContext);
  const navigate = useNavigate();
  const [accountModalIsOpen, setAccountModalIsOpen] = useState(false);

  function handleAccountModal() {
    setAccountModalIsOpen((is) => !is);
  }

  if (null === auth.user) {
    return <></>;
  }

  return (
    <>
      <Box
        role={"button"}
        m={0.5}
        border={2}
        height={48}
        width={48}
        minWidth={48}
        boxSizing={"border-box"}
        borderRadius={"50%"}
        borderColor={"secondary.main"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={handleAccountModal}
      >
        <Typography variant={"h3"} component={"span"}>
          {auth.user?.initiales}
        </Typography>
      </Box>
      <Modal
        open={accountModalIsOpen}
        onClose={handleAccountModal}
        keepMounted
        disableEnforceFocus
        disableAutoFocus
        sx={{
          width: "100vw",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box bgcolor={"common.white"} width={"80%"} maxWidth={500} p={2}>
          <Typography variant={"h1"} align={"center"}>
            Mon compte
          </Typography>
          <Typography variant={"body1"} align={"center"}>
            {uiHelper.formatNameFromEmail(auth.user.email)}
          </Typography>
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Button
              variant={"contained"}
              onClick={() => {
                auth.logout(() => navigate("/"));
              }}
            >
              Me déconnecter
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
