import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Rating, Typography } from "@mui/material";
import { AppIcon } from "../../Common/components/AppIcon";
import companyPlaceholder from "../../assets/images/company_placeholder.png";
import { ServicesList } from "./ServicesList";
import { CompanyType } from "../../Common/model/types";

function CompanyPicture({ company }: { company: CompanyType }) {
  const [companyPictures, setCompanyPictures] = useState<string[]>([]);

  function getCompanyPictures(company: CompanyType) {
    const companyPictures: string[] = [];
    company.entreprise.bureaux.forEach((bureau) => {
      companyPictures.push(bureau.url240);
    });
    setCompanyPictures(companyPictures);
  }

  useEffect(() => getCompanyPictures(company), []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <picture style={{ aspectRatio: "1/1", display: "flex", width: "100%" }}>
          <img
            src={
              "" !== companyPictures[0]
                ? companyPictures[0]
                : companyPlaceholder
            }
            alt={company.entreprise.nom}
            style={{ height: "100%", objectFit: "cover", width: "100%" }}
          />
        </picture>
      </Grid>
      <Grid item xs={4}>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <picture
            style={{ aspectRatio: "1/1", display: "flex", width: "100%" }}
          >
            <img
              src={
                "" !== companyPictures[1]
                  ? companyPictures[1]
                  : companyPlaceholder
              }
              alt={company.entreprise.nom}
              style={{ height: "100%", objectFit: "cover", width: "100%" }}
            />
          </picture>
          <picture
            style={{ aspectRatio: "1/1", display: "flex", width: "100%" }}
          >
            <img
              src={
                "" !== companyPictures[2]
                  ? companyPictures[2]
                  : companyPlaceholder
              }
              alt={company.entreprise.nom}
              style={{ height: "100%", objectFit: "cover", width: "100%" }}
            />
          </picture>
        </Box>
      </Grid>
    </Grid>
  );
}

export function CompanyDetail({
  company,
}: {
  company: CompanyType;
}): React.ReactElement {
  return (
    <>
      <CompanyPicture company={company} />
      <Box p={2}>
        <Typography variant={"h1"}>{company.entreprise.nom}</Typography>
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <Box display={"flex"} alignItems={"center"} gap={0.5}>
            <Typography variant={"body2"}>3.6</Typography>
            <Rating
              name={`${company.entreprise.id}_rating`}
              value={company.entreprise.rating}
              precision={0.5}
              size={"small"}
            />
            <Typography variant={"body2"} color={"text.secondary"}>
              (XXX)
            </Typography>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={0.5}
            color={"secondary.main"}
            bgcolor={"secondary.light"}
            px={1}
            py={0.5}
            borderRadius={4}
          >
            <AppIcon name={"directions_walk"} size={16} />
            <Typography variant={"body2"} color={"inherit"}>
              350 m
            </Typography>
          </Box>
        </Box>

        <ServicesList services={company.entreprise.services} />
      </Box>

      <Divider />
    </>
  );
}
