import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { SERVICES } from "../../Common/domain/helpers/serviceshelper";

const INITIAL_DISPLAYED_SERVICES_COUNT = 3;

export function ServicesList({
  services,
}: {
  services: number[];
}): React.ReactElement {
  const [visiblesServicesList, setVisibleServicesList] = useState<number[]>([]);

  useEffect(() => {
    setVisibleServicesList(
      [...services].splice(0, INITIAL_DISPLAYED_SERVICES_COUNT)
    );
  }, [services]);

  function showMore() {
    setVisibleServicesList(services);
  }

  if (0 === services.length) {
    return <></>;
  }

  return (
    <>
      <Box display={"flex"} alignItems={"center"} gap={2} pt={2}>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          {services.map((serviceId: number) => {
            const isHidden =
              undefined ===
              visiblesServicesList.find((itemId) => itemId === serviceId);
            return (
              <div
                key={serviceId}
                hidden={isHidden}
                dangerouslySetInnerHTML={{
                  __html: SERVICES[serviceId].content,
                }}
              />
            );
          })}
        </Box>
        {INITIAL_DISPLAYED_SERVICES_COUNT < services.length &&
          visiblesServicesList !== services && (
            <Typography
              variant={"body2"}
              style={{ textDecoration: "underline" }}
              onClick={showMore}
            >
              Plus de services
            </Typography>
          )}
      </Box>
    </>
  );
}
