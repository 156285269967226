import mapboxgl, { LngLatLike } from "mapbox-gl";
import {
  CatalogContextType,
  CompanyType,
  MapCoords,
  MapInfosType,
} from "../../model/types";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { ClickableMarker } from "../../model/ClickableMarker";
import { companyHelper } from "./companyHelper";

export const mapHelper = {
  PROFILES_ICONS: {
    1: "",
    2: "",
    3: "restaurant",
  } as Record<number, string>,
  INITIAL_MAP_ZOOM: 12,
  MAP_CENTER: {
    lng: "2.32",
    lat: "48.8569444",
  },
  GEOCODING_URL: "https://api.mapbox.com/geocoding/v5/mapbox.places/",
  flyToPosition: (
    map: { current: mapboxgl.Map | null },
    position: GeolocationPosition | null
  ): void => {
    if (null === position || null === map.current) {
      return;
    }
    map.current.flyTo({
      center: [position.coords.longitude, position.coords.latitude],
    });
  },
  getItineraryBetweenUserAndEntreprise: (
    mapCoords: MapCoords | null,
    entreprise: { latitude: number; longitude: number }
  ): string => {
    if (null === mapCoords) {
      return `https://www.google.com/maps/dir/${mapHelper.MAP_CENTER?.lat},${mapHelper.MAP_CENTER?.lng}/${entreprise.latitude},${entreprise.longitude}`;
    }
    return `https://www.google.com/maps/dir/${mapCoords?.lat},${mapCoords?.lng}/${entreprise.latitude},${entreprise.longitude}`;
  },
  getPlacesFromString: (
    searchText: string
  ): Promise<{
    features: { place_name: string; geometry: { coordinates: number[] } }[];
  }> => {
    return fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?access_token=${process.env.MAPBOX_ACCESS_TOKEN}`
    )
      .then((r) => r.json())
      .then((json) => {
        return json;
      });
  },
  /**
   * Initialise la map et les listeners
   * @param map
   * @param mapContainer
   * @param mapInfos
   * @param handleMarkerClick
   */
  createMap: (
    map: MutableRefObject<mapboxgl.Map | null>,
    mapContainer: HTMLDivElement | null,
    mapInfos: MapInfosType,
    handleMarkerClick: Dispatch<SetStateAction<CompanyType | null>>
  ): void => {
    if (null === mapContainer) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [
        Number.parseFloat(mapHelper.MAP_CENTER.lng),
        Number.parseFloat(mapHelper.MAP_CENTER.lat),
      ],
      zoom: mapHelper.INITIAL_MAP_ZOOM,
    });

    map.current.on("click", () => {
      handleMarkerClick(null);
    });

    map.current.on("load", () => {
      if (null === map) {
        return;
      }
      const center = map.current?.getCenter();
      const event = new CustomEvent("catalog.search.map.load", {
        detail: {
          coordinates:
            (center?.lat.toFixed(4) as string) +
            "," +
            (center?.lng.toFixed(4) as string),
        },
      });
      window.dispatchEvent(event);

      mapInfos.setMapCoords({
        lat: center ? center.lat.toFixed(4) : "",
        lng: center ? center.lng.toFixed(4) : "",
      });
    });

    map.current.on("move", () => {
      if (null === map) {
        return;
      }
      const center = map.current?.getCenter();
      const event = new CustomEvent("catalog.search.map.move", {
        detail: {
          coordinates:
            (center?.lat.toFixed(4) as string) +
            "," +
            (center?.lng.toFixed(4) as string),
        },
      });
      window.dispatchEvent(event);

      mapInfos.setMapCoords({
        lat: center ? center.lat.toFixed(4) : "",
        lng: center ? center.lng.toFixed(4) : "",
      });
    });
  },
  createMarkers: (
    map: mapboxgl.Map | null,
    catalog: CatalogContextType,
    handleMarkerClick: Dispatch<SetStateAction<CompanyType | null>>
  ): void => {
    if (null === map || null === catalog.results) {
      return;
    }

    const displayedMarkers: ClickableMarker[] = [];

    for (const marker in displayedMarkers) {
      displayedMarkers[marker].remove();
      delete displayedMarkers[marker];
    }

    for (const company of catalog.results) {
      if (0 === company.entreprise.bureaux.length) {
        return;
      }

      const el = document.createElement("div");
      el.classList.add("customMapMarker");
      el.innerHTML = companyHelper.getLowerOfficeCost(company);

      /* 
        Les entreprises ont un profil (Financeurs, coworkings ou restaurants)
        Si c'est un restaurant, on ajoute une icone pour le différencier.
      */
      company.entreprise.profiles.map((profile) => {
        if ("" !== mapHelper.PROFILES_ICONS[profile]) {
          const icon = document.createElement("span");
          icon.classList.add("material-symbols-sharp", "icon");
          icon.innerHTML = mapHelper.PROFILES_ICONS[profile];

          el.prepend(icon);
        }
      });

      if (
        undefined !==
        catalog.favoritesCompanies.find(
          (fav) => fav.entreprise.id === company.entreprise.id
        )
      ) {
        const span = document.createElement("span");
        span.classList.add("material-symbols-sharp", "filled", "favorite");
        span.innerHTML = "favorite";
        el.append(span);
      }

      displayedMarkers.push(
        new ClickableMarker(el)
          .setLngLat([
            Number(company.entreprise.longitude),
            Number(company.entreprise.latitude),
          ] as LngLatLike)
          .addTo(map as mapboxgl.Map)
          .onClick(() => {
            document.querySelectorAll(".customMapMarker").forEach((marker) => {
              marker.classList.remove("active");
            });
            el.classList.add("active");
            handleMarkerClick(company);
          })
      );
    }
  },
};
