import mapboxgl from "mapbox-gl";

/**
 * extend mapboxGL Marker, so we can pass in an onClick handler
 */
export class ClickableMarker extends mapboxgl.Marker {
  // new method onClick, sets _handleClick to a function you pass in
  private _element: Element | undefined;
  private _handleClick: (() => void) | undefined;

  onClick(handleClick: () => void) {
    this._handleClick = handleClick;
    return this;
  }

  // the existing _onMapClick was there to trigger a popup
  // we are hijacking it to run a function we define
  _onMapClick(e: { originalEvent: { target: Node | null } }) {
    const targetElement = e.originalEvent.target;
    const element = this._element;

    if (
      this._handleClick &&
      (targetElement === element || element?.contains(targetElement))
    ) {
      this._handleClick();
    }
  }
}
