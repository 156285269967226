// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { Box } from "@mui/material";

/**
 * Composant pour utiliser les icones tirées de https://fonts.google.com/icons
 * @param name
 * @param filled
 * @param color
 * @param size
 * @param onClick
 * @constructor
 */
export const AppIcon = ({
  name,
  filled = true,
  color = "inherit",
  size = 24,
  onClick,
}: {
  name: string;
  filled?: boolean;
  color?: string;
  size?: number;
  onClick?: () => void;
}) => {
  return (
    <Box
      component={"span"}
      color={color}
      fontSize={size}
      width={size}
      className={`material-symbols-sharp ${filled ? "filled" : ""}`}
      onClick={onClick}
    >
      {name}
    </Box>
  );
};
