import React from "react";
import { Box, Collapse, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CompanyThumbnail } from "./CompanyThumbnail";
import { CompanyType } from "../../Common/model/types";

const useStyles = makeStyles({
  collapseContainer: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    display: "flex",
    alignItems: "flex-end",
    pointerEvents: "none",
  },
  collapseRoot: { width: "100%" },
});

export function ResultsThumbnailsList({
  results,
  open,
  containerHeight,
}: {
  results: CompanyType[];
  open: boolean;
  containerHeight?: number;
}): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.collapseContainer}>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        classes={{ root: classes.collapseRoot }}
      >
        <Box
          p={2}
          style={{
            background: "white",
            boxSizing: "border-box",
            height: containerHeight,
            pointerEvents: "all",
            overflow: "auto",
          }}
        >
          <div style={{ height: "100%" }}>
            {results.length === 0 ? (
              <Typography>{"Aucun résultat trouvé"}</Typography>
            ) : (
              <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                {results.map((company) => {
                  if (0 === company.entreprise.bureaux.length) {
                    return (
                      <React.Fragment
                        key={company.entreprise.id}
                      ></React.Fragment>
                    );
                  }
                  return (
                    <Box
                      key={company.entreprise.id}
                      pb={2}
                      sx={(theme) => ({
                        "&:not(:first-of-type)": {
                          borderTop: `1px solid ${theme.palette.grey[100]}`,
                          paddingTop: 2,
                        },
                      })}
                    >
                      <CompanyThumbnail company={company} />
                    </Box>
                  );
                })}
              </ul>
            )}
          </div>
        </Box>
      </Collapse>
    </div>
  );
}
