import React, { useContext } from "react";
import { AppContext } from "../Common/context/ContextContainer";
import { Box, Typography } from "@mui/material";
import { AppIcon } from "../Common/components/AppIcon";
import { BookingThumbnail } from "./BookingThumbnail";
import { AppPageLayout } from "../Common/components/AppPageLayout";
import { MyBookingType } from "../Common/model/types";

export function Booking(): React.ReactElement {
  const { catalog } = useContext(AppContext);

  if (!catalog.bookings) {
    return <></>;
  }

  const bookings = catalog.bookings.filter((booking) => booking.statut !== 4);

  return (
    <AppPageLayout>
      <Box display={"flex"} flexDirection={"column"} height={"100%"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={2}
        >
          <Typography variant={"h1"}>Réservations</Typography>
          <AppIcon name={"history"} filled={false} />
        </Box>
        <Box sx={{ flexGrow: 1, overflow: "auto" }}>
          {0 === bookings.length ? (
            <Box p={2}>
              <Typography>
                Vous n&apos;avez pas de réservations en cours
              </Typography>
            </Box>
          ) : (
            bookings.map((booking: MyBookingType, index: number) => {
              return <BookingThumbnail key={index} booking={booking} />;
            })
          )}
        </Box>
      </Box>
    </AppPageLayout>
  );
}
