import { Nullable } from "../../src/global";
import { formatDateForApi } from "./date";

export function set(storage: Storage, key: string, value: unknown): void {
  storage.setItem(key, JSON.stringify(value));
  storage.setItem(
    `${key}.metas`,
    JSON.stringify({ createdAt: formatDateForApi(new Date()) })
  );
}

export function get<T>(
  storage: Storage,
  key: string,
  defaultValue: Nullable<T> = null
): Nullable<T> {
  const value = storage.getItem(key);
  return null === value ? defaultValue : JSON.parse(value);
}

export function remove<T>(storage: Storage, key: string): Nullable<T> {
  const item = get<T>(storage, key);
  storage.removeItem(key);
  storage.removeItem(`${key}.metas`);

  return item;
}
