import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
} from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { AppContext } from "../../Common/context/ContextContainer";
import { search } from "../../Common/domain/helpers/client";
import { mapHelper } from "../../Common/domain/helpers/mapHelper";
import { CompanyType, SearchQueryType } from "../../Common/model/types";

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN as string;

export default function Map({
  setSelectedCompany,
  mapCoordinates,
}: {
  setSelectedCompany: Dispatch<SetStateAction<CompanyType | null>>;
  mapCoordinates: number[] | null;
}): React.ReactElement {
  const { catalog, auth, mapInfos } = useContext(AppContext);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  function getInitialCompanies() {
    if (null !== catalog.results || null !== catalog.searchQuery) {
      return;
    }
    async function fetchData() {
      return await search({
        latitude: map.current?.getCenter().lat,
        longitude: map.current?.getCenter().lng,
        radius: 150,
      } as SearchQueryType);
    }

    fetchData().then((response) => catalog.setResults(response.results));
  }

  useEffect(getInitialCompanies, [map.current]);

  useEffect(
    () =>
      mapHelper.createMap(
        map,
        mapContainer.current,
        mapInfos,
        setSelectedCompany
      ),
    [map, mapContainer.current]
  );

  useEffect(
    () => mapHelper.createMarkers(map.current, catalog, setSelectedCompany),
    [catalog.results, catalog.favoritesCompanies, map.current]
  );

  useEffect(
    () => mapHelper.flyToPosition(map, auth.userPosition),
    [map, auth.userPosition]
  );

  /**
   * mapCoordinates provient du searchEngine
   */
  useEffect(() => {
    if (null === mapCoordinates) {
      return;
    }
    const coords = {
      coords: { longitude: mapCoordinates[0], latitude: mapCoordinates[1] },
    };
    mapHelper.flyToPosition(map, coords as unknown as GeolocationPosition);
  }, [mapCoordinates]);

  return (
    <div
      ref={mapContainer}
      style={{ height: "100%", width: "100%" }}
      className={"map-container"}
    />
  );
}
