import React from "react";
import { Box, SwipeableDrawer } from "@mui/material";

export function AppSwipeableDrawer({
  open,
  onOpen,
  onClose,
  children,
}: {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  children: React.ReactElement;
}) {
  return (
    <Box
      sx={{
        width: "100%",
        pointerEvents: "all",
        position: "relative",
      }}
    >
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        disableSwipeToOpen={false}
        disablePortal
        hideBackdrop
        ModalProps={{
          keepMounted: true,
          disableEnforceFocus: true,
          disableAutoFocus: true,
        }}
        PaperProps={{
          elevation: 0,
          style: {
            position: "relative",
            zIndex: 100,
          },
        }}
        sx={{
          position: "relative",
          height: open ? "100%" : 0,
        }}
      >
        <Box display={"flex"} justifyContent={"center"} pt={0.5} pb={2}>
          <Box
            sx={{
              width: 48,
              height: 4,
              backgroundColor: "#E7E7E7",
              borderRadius: 2,
            }}
          />
        </Box>

        <Box
          style={{ backgroundColor: "white" }}
          mb={open ? 2 : 0}
          mx={open ? 2 : 0}
        >
          {children}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}
