/* eslint-disable @typescript-eslint/ban-ts-comment */

// interface SearchParams {
//   [key: string]: string | string[];
// }
//
// export function searchParamsToObject(): SearchParams {
//   const searchParams = new URLSearchParams(window.location.search);
//   const result: SearchParams = {};
//   for (const key of searchParams.keys()) {
//     // @ts-ignore
//     result[key] = key.includes("[]")
//       ? searchParams.getAll(key)
//       : searchParams.get(key);
//   }
//
//   return result;
// }

/**
 * Ne prends pas en compte les tableaux avec un index, faudrait faire une regex de remplacement.
 */
export function objectToQuery(values: object): string {
  const searchParams = new URLSearchParams();
  Object.entries(values).forEach((param) => {
    const [key, value] = param;
    if (Array.isArray(value)) {
      value.forEach((subValue) => {
        searchParams.append(`${key}[]`, subValue);
      });
    } else {
      searchParams.append(key, value);
    }
  });

  // @ts-ignore
  return searchParams.toString().replaceAll("%5B%5D", "[]");
}
