import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { AppContext } from "../../Common/context/ContextContainer";
import { debounce } from "../../../lib/js/debounce";
import "swiper/swiper.scss";
import { CompanyThumbnail } from "./CompanyThumbnail";
import { SearchEngineForm } from "./SearchEngineForm";
import { AppSwipeableDrawer } from "../../Common/components/AppSwipeableDrawer";
import { NextBookingThumbnail } from "./NextBookingThumbnail";
import { SettingsPanel } from "./SettingsPanel";
import { AppSettingsCta } from "../../Common/components/AppSettingsCta";
import { Box, Typography } from "@mui/material";
import { AppIcon } from "../../Common/components/AppIcon";
import { ResultsThumbnailsList } from "./ResultsThumbnailsList";
import { formValuesToSearchQuery } from "../../Common/domain/helpers/formHelper";
import {
  CompanyType,
  MyBookingType,
  SearchQueryType,
} from "../../Common/model/types";

export function SearchEngine({
  selectedCompany,
  setMapCoordinates,
}: {
  selectedCompany: CompanyType | null;
  setMapCoordinates: Dispatch<SetStateAction<number[] | null>>;
}): React.ReactElement {
  const { catalog } = useContext(AppContext);
  const form = useForm<SearchQueryType>();
  const { register, control, getValues, setValue, reset, formState } = form;

  const watchAll = useWatch({ control });

  async function handleChange() {
    const searchQuery: SearchQueryType = getValues();
    catalog.setSearchQuery(formValuesToSearchQuery(searchQuery));
  }

  useEffect(() => {
    // récupération du form par le storage
    const searchQuery = catalog.searchQuery;
    if (null !== searchQuery) {
      reset(formValuesToSearchQuery(searchQuery));
    } else {
      /*@todo: utiliser valeurs en cache, si cache*/
      setValue("conveniences", []);
      setValue("date", null);
      setValue("radius", null);
    }

    window.addEventListener(
      "catalog.search.map.load",
      debounce((event) => {
        setValue("coordinates", event.detail.coordinates, {
          shouldDirty: true,
        });
      }, 350)
    );

    window.addEventListener(
      "catalog.search.map.move",
      debounce((event) => {
        setValue("coordinates", event.detail.coordinates, {
          shouldDirty: true,
        });
      }, 350)
    );
  }, []);

  useEffect(() => {
    if (formState.isDirty) {
      handleChange();
    }
  }, [watchAll, formState.isDirty]);

  const [nextBooking, setNextBooking] = useState<
    MyBookingType | undefined | null
  >(null);

  const [openedPanel, setOpenedPanel] = useState<
    "results" | "nextBooking" | "selectedCompany" | null
  >(null);

  useEffect(() => {
    if (catalog.bookings) {
      setNextBooking(
        catalog.bookings.find((resa: MyBookingType) => resa.statut !== 4)
      );
    }
  }, [catalog.bookings]);

  useEffect(() => {
    if (nextBooking && !catalog.nextBookingViewed) {
      catalog.setNextBookingViewed(true);
      setTimeout(() => setOpenedPanel("nextBooking"), 2700);
    }
  }, [nextBooking]);

  useEffect(() => {
    setOpenedPanel(null !== selectedCompany ? "selectedCompany" : null);
  }, [selectedCompany]);

  function handleResultsPanel() {
    if ("results" !== openedPanel) {
      setOpenedPanel("results");
    } else {
      setOpenedPanel(null);
    }
  }

  // On a besoin de récupérer la height du container pour la liste de résultats
  const panelsContainer = useRef<HTMLDivElement>(null);

  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);

  function toggleSettingsDrawer() {
    setIsSettingsDrawerOpen((isOpen) => !isOpen);
  }

  return (
    <div
      style={{
        boxSizing: "border-box",
        position: "absolute",
        zIndex: 100,
        height: "100%",
        width: "100%",
        top: 0,
        pointerEvents: "none",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {/*input et filtres*/}
        <SearchEngineForm
          form={form}
          isResultsPanelOpen={"results" === openedPanel}
          handleResultsPanel={handleResultsPanel}
          register={register}
          setValue={setValue}
          toggleSettingsDrawer={toggleSettingsDrawer}
          setMapCoordinates={setMapCoordinates}
        />

        <div
          ref={panelsContainer}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",
            flexGrow: 1,
            position: "relative",
          }}
        >
          {/*filtres cta et panel*/}
          <AppSettingsCta toggleSettingsDrawer={toggleSettingsDrawer} />
          <FormProvider {...form}>
            <SettingsPanel
              isSettingsDrawerOpen={isSettingsDrawerOpen}
              toggleSettingsDrawer={toggleSettingsDrawer}
            />
          </FormProvider>

          {/*resultats cta et panel*/}
          <Box
            display={"flex"}
            alignItems={"flex-end"}
            flexGrow={1}
            position={"relative"}
            width={"100%"}
          >
            {null !== catalog.results && 0 < catalog.results.length && (
              <>
                <Box display={"flex"} p={2}>
                  <Box
                    role={"button"}
                    onClick={handleResultsPanel}
                    sx={{
                      background: "white",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      padding: 1.5,
                      pointerEvents: "all",
                    }}
                  >
                    <AppIcon name={"list"} />
                    <Typography>Afficher la liste</Typography>
                  </Box>
                </Box>
                <ResultsThumbnailsList
                  results={catalog.results}
                  open={"results" === openedPanel}
                  containerHeight={panelsContainer?.current?.offsetHeight}
                />
              </>
            )}
          </Box>

          {/*prochaine réservation panel*/}
          {/*// si on a une reservation pour les prochaines 24 h le panneau doit s'afficher au mount et dès qu'on ferme un des autres*/}
          <AppSwipeableDrawer
            open={"nextBooking" === openedPanel}
            onOpen={() => setOpenedPanel("nextBooking")}
            onClose={() => setOpenedPanel(null)}
          >
            <NextBookingThumbnail nextBooking={nextBooking} />
          </AppSwipeableDrawer>

          {/*company panel ouvrable au click sur un marker de la map*/}
          <AppSwipeableDrawer
            open={"selectedCompany" === openedPanel}
            onOpen={() => setOpenedPanel("selectedCompany")}
            onClose={() => setOpenedPanel(null)}
          >
            <CompanyThumbnail company={selectedCompany} />
          </AppSwipeableDrawer>
        </div>
      </div>
    </div>
  );
}
