import React, { useEffect, useState } from "react";
import {
  AppContextType,
  AuthContextType,
  CatalogContextType,
  CompanyType,
  MapCoords,
  MyBookingType,
  SearchQueryType,
  UserType,
} from "../model/types";
import { apiAuthProvider } from "../../User/authProvider";
import {
  CompanyItemResponse,
  executeNinoxQuery,
  getBookingsForBeneficiaire,
  search,
} from "../domain/helpers/client";
import { useGeolocation } from "../domain/hooks/useGeolocation";

export const UiContext = React.createContext({
  theme: "light",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleTheme: () => {},
});

/* eslint-disable @typescript-eslint/no-empty-function */
export const AppContext = React.createContext<AppContextType>({
  auth: {
    user: null,
    login: () => {},
    logout: () => {},
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    signin: () => {},
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reinitPassword: () => {},
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newPassword: () => {},
    isLoginModalOpen: false,
    openLoginModal: () => {},
    closeLoginModal: () => {},
    userPosition: null,
    setUser: () => {},
  },
  catalog: {
    results: null,
    setResults: () => {},
    searchQuery: null,
    setSearchQuery: () => {},
    bookings: null,
    getReservations: () => {},
    favoritesCompanies: [],
    setFavoritesCompanies: () => {},
    getUserFavorites: () => {},
    nextBookingViewed: false,
    setNextBookingViewed: () => {},
    selectedCompany: null,
    setSelectedCompany: () => {},
  },
  app: {
    appIsReady: false,
    appIsLoaded: false,
    setAppIsLoaded: () => {},
  },
  mapInfos: {
    mapCoords: null,
    setMapCoords: () => {},
  },
});
/* eslint-enable @typescript-eslint/no-empty-function */

export function ContextContainer({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [results, setResults] = useState<
    CompanyType[] | CompanyItemResponse[] | null
  >(null);

  const [searchQuery, setSearchQuery] = useState<SearchQueryType | null>(null);
  const [bookings, setBookings] = useState<MyBookingType[] | null>(null);
  const [favoritesCompanies, setFavoritesCompanies] = useState<CompanyType[]>(
    []
  );
  const [nextBookingViewed, setNextBookingViewed] = useState<boolean>(false);
  const [appIsLoaded, setAppIsLoaded] = useState<boolean>(false);
  const [appIsReady, setAppIsReady] = useState<boolean>(false);
  const [mapCoords, setMapCoords] = useState<MapCoords | null>(null);
  const [userPosition] = useGeolocation();

  const [selectedCompany, setSelectedCompany] = useState<CompanyType | null>(
    null
  );

  const mapInfos = {
    mapCoords: mapCoords,
    setMapCoords: setMapCoords,
  };

  const catalog: CatalogContextType = {
    results,
    setResults,
    searchQuery,
    setSearchQuery,
    bookings,
    getReservations,
    favoritesCompanies,
    setFavoritesCompanies,
    getUserFavorites,
    nextBookingViewed,
    setNextBookingViewed,
    selectedCompany,
    setSelectedCompany,
  };

  useEffect(() => {
    if (null === searchQuery) {
      return;
    }

    async function fetchData() {
      const response = await search(searchQuery as SearchQueryType);
      setResults(response.results);
    }

    fetchData();
  }, [searchQuery]);

  const [user, setUser] = useState<UserType | null>(
    apiAuthProvider.getStoredUser()
  );

  function getUserFavorites() {
    executeNinoxQuery(`getFavoritesForBeneficiaire(${user?.ninoxId})`).then(
      (response) => {
        setFavoritesCompanies(response as CompanyType[]);
      }
    );
  }

  useEffect(() => {
    if (null === user) {
      return;
    }
    getUserFavorites();
  }, [user]);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);

  const auth: AuthContextType = {
    user: user,
    login: function (username, password, callback, errorCallback) {
      apiAuthProvider.login(
        username,
        password,
        (user) => {
          setUser(user);
          callback(user);
        },
        errorCallback
      );
    },
    logout: function (callback: VoidFunction) {
      apiAuthProvider.logout(() => {
        setUser(null);
        callback();
      });
    },
    signin: function (email, plainPassword) {
      return apiAuthProvider.signin(email, plainPassword);
    },
    reinitPassword: function (email, callback, errorCallback) {
      return apiAuthProvider.reinitPassword(email, callback, errorCallback);
    },
    newPassword: function (
      email,
      token,
      plainPassword,
      callback,
      errorCallback
    ) {
      return apiAuthProvider.newPassword(
        email,
        token,
        plainPassword,
        callback,
        errorCallback
      );
    },
    isLoginModalOpen: isLoginModalOpen,
    openLoginModal: () => setIsLoginModalOpen(true),
    closeLoginModal: () => setIsLoginModalOpen(false),
    userPosition: userPosition,
    setUser: setUser,
  };

  const app = {
    appIsReady: appIsReady,
    appIsLoaded: appIsLoaded,
    setAppIsLoaded: setAppIsLoaded,
  };

  const [theme, setTheme] = useState<string>("light");
  function toggleTheme() {
    setTheme(theme === "light" ? "dark" : "light");
  }

  function getReservations() {
    if (null === user) {
      return;
    }

    getBookingsForBeneficiaire(user.ninoxId).then((resp) => {
      setBookings(resp["hydra:member"]);
    });
  }

  useEffect(getReservations, [user]);

  // Pour l'animation du logo dans le moteur de recherche
  useEffect(() => {
    setTimeout(() => setAppIsReady(true), 300);
    setTimeout(() => setAppIsLoaded(true), 3000);
  }, [catalog.bookings]);

  return (
    <AppContext.Provider value={{ catalog, auth, app, mapInfos }}>
      <UiContext.Provider value={{ theme: "light", toggleTheme }}>
        {children}
      </UiContext.Provider>
    </AppContext.Provider>
  );
}
