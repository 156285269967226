import React, { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Drawer, Typography } from "@mui/material";
import { AppIcon } from "../../Common/components/AppIcon";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import {
  DATE_FIELDS_OPTIONS,
  DISTANCE_FIELDS_OPTIONS,
  NOTE_FIELDS_OPTIONS,
} from "../../Common/domain/helpers/formHelper";
import { AppFilterButton } from "../../Common/components/AppFilterButton";
import { AppConvenienceButton } from "../../Common/components/AppConvenienceButton";
import { SERVICES } from "../../Common/domain/helpers/serviceshelper";
import { ServiceType } from "../../Common/model/types";

const SwiperItem = ({
  label,
  drawerContent,
  icon,
}: {
  label: string;
  drawerContent: React.ReactNode;
  icon?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDrawer = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <div
        role={"button"}
        onClick={handleDrawer}
        style={{
          background: "white",
          borderRadius: 16,
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.35)",
          boxSizing: "border-box",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: 8,
          margin: "16px 0",
          minHeight: 32,
          padding: 8,
          width: "max-content",
        }}
      >
        {icon && <AppIcon name={icon} size={16} filled={false} />}
        <Typography variant={"caption"}>{label}</Typography>
        <AppIcon name={"expand_more"} size={16} />
      </div>
      <Drawer
        anchor={"bottom"}
        open={isOpen}
        onClose={handleDrawer}
        sx={{ zIndex: 1600 }}
      >
        <Box py={3} px={2}>
          {drawerContent}
        </Box>
      </Drawer>
    </>
  );
};

/**
 * Le swiper des options
 * @constructor
 */
export function SettingsSwiper() {
  const { register, watch, setValue, control } = useFormContext();

  const watchAll = useWatch({ control });

  const conveniencesValues = watch("conveniences");

  const handleConvenienceChange = (service: ServiceType) => {
    const conveniences = conveniencesValues;
    if (undefined === conveniences) {
      setValue("conveniences", conveniences, { shouldDirty: true });
      return;
    }
    if (conveniences && conveniences.includes(service.label)) {
      conveniences.splice(conveniences.indexOf(service.label), 1);
    } else {
      conveniences.push(service.label);
    }
    setValue("conveniences", conveniences, { shouldDirty: true });
  };

  function getDistanceLabel(): string {
    const distanceItem = DISTANCE_FIELDS_OPTIONS.find(
      (item) => item.key === watchAll.radius
    );
    if (undefined !== distanceItem && "plus" !== distanceItem.value) {
      return distanceItem.value;
    }
    return "Distance";
  }

  return (
    <>
      <Swiper spaceBetween={8} slidesPerView={"auto"} style={{ flexGrow: 1 }}>
        <SwiperSlide style={{ width: "max-content" }}>
          <SwiperItem
            icon={"nest_clock_farsight_analog"}
            label={
              watchAll.date
                ? DATE_FIELDS_OPTIONS[watchAll.date]
                : "Disponibilité"
            }
            drawerContent={
              <>
                <Box display={"flex"} gap={1} mb={2}>
                  <AppIcon name={"schedule"} filled={false} />
                  <Typography variant={"h3"}>Disponibilité</Typography>
                </Box>
                <input type={"hidden"} {...register("date")} />
                <Box
                  display={"flex"}
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.grey[100]}`,
                    borderRadius: "16px",
                    width: "min-content",
                  })}
                >
                  {Object.entries(DATE_FIELDS_OPTIONS).map(([key, value]) => (
                    <AppFilterButton
                      key={key}
                      isActive={key === watch("date")}
                      label={value}
                      onClick={() => setValue("date", key)}
                    />
                  ))}
                </Box>
              </>
            }
          />
        </SwiperSlide>
        <SwiperSlide style={{ width: "max-content" }}>
          <SwiperItem
            icon={"directions_walk"}
            label={getDistanceLabel()}
            drawerContent={
              <>
                <Box display={"flex"} gap={1} mb={2}>
                  <AppIcon name={"directions_walk"} filled={false} />
                  <Typography variant={"h3"}>Distance</Typography>
                </Box>
                <input type={"hidden"} {...register("radius")} />
                <Box
                  display={"flex"}
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.grey[100]}`,
                    borderRadius: "16px",
                    width: "min-content",
                  })}
                >
                  {DISTANCE_FIELDS_OPTIONS.map((item) => (
                    <AppFilterButton
                      key={item.key}
                      isActive={item.key === watchAll.radius}
                      label={item.value}
                      onClick={() =>
                        setValue("radius", item.key, {
                          shouldDirty: true,
                        })
                      }
                    />
                  ))}
                </Box>
              </>
            }
          />
        </SwiperSlide>
        <SwiperSlide style={{ width: "max-content" }}>
          <SwiperItem
            icon={"hotel_class"}
            label={watchAll.note ? NOTE_FIELDS_OPTIONS[watchAll.note] : "Note"}
            drawerContent={
              <>
                <Box display={"flex"} gap={1} mb={2}>
                  <AppIcon name={"hotel_class"} filled={false} />
                  <Typography variant={"h3"}>Note</Typography>
                </Box>
                <input type={"hidden"} {...register("note")} />
                <Box
                  display={"flex"}
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.grey[100]}`,
                    borderRadius: "16px",
                    width: "min-content",
                  })}
                >
                  {Object.entries(NOTE_FIELDS_OPTIONS).map(([key, value]) => (
                    <AppFilterButton
                      key={key}
                      isActive={key === watch("note")}
                      label={value}
                      onClick={() => setValue("note", key)}
                    />
                  ))}
                </Box>
              </>
            }
          />
        </SwiperSlide>
        <SwiperSlide style={{ width: "max-content" }}>
          <SwiperItem
            icon={"settings"}
            label={
              undefined !== conveniencesValues && 0 < conveniencesValues.length
                ? conveniencesValues.join(", ")
                : "Services"
            }
            drawerContent={
              <>
                <Box display={"flex"} gap={1} mb={2}>
                  <AppIcon name={"settings"} filled={false} />
                  <Typography variant={"h3"}>Services</Typography>
                </Box>
                <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                  {Object.entries(SERVICES).map(([key, service]) => (
                    <AppConvenienceButton
                      key={key}
                      convenience={service}
                      isActive={
                        undefined !== conveniencesValues &&
                        conveniencesValues.includes(service.label)
                      }
                      onClick={() => handleConvenienceChange(service)}
                    />
                  ))}
                </Box>
              </>
            }
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
