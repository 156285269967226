import * as storageWrapper from "./Storage";

export function getCached<T>(
  key: string,
  callback: () => Promise<T>,
  storage: Storage = sessionStorage
): Promise<T> {
  const storedItem = storageWrapper.get<T>(storage, key);
  if (storedItem !== null) {
    return Promise.resolve(storedItem);
  }

  return callback().then((response): T => {
    storageWrapper.set(storage, key, response);
    return response;
  });
}
