export const uiHelper = {
  getTomorrowDate: (): Date => {
    return new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  },
  formatDate: (date: Date, withYear = false): string => {
    try {
      return new Intl.DateTimeFormat("fr-FR", {
        weekday: "short",
        month: "long",
        day: "numeric",
        year: withYear ? "numeric" : undefined,
      }).format(date);
    } catch (err) {
      return "Lun. 6 oct 2022";
    }
  },
  formatDateForBooking: (date: Date): string => {
    return new Intl.DateTimeFormat("fr-FR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    })
      .format(date)
      .replace(/\//g, "-");
  },
  formatHour: (hour?: string): string => {
    if (undefined === hour) {
      return "9h00";
    }
    return hour.split(" ")[0].replace(":", "h");
  },
  formatNameFromEmail: (email: string): string => {
    const emailFirstPart = email.split("@")[0];
    const firstname = emailFirstPart.split(".")[0];
    const lastName = emailFirstPart.split(".")[1];

    return `${firstname} ${lastName}`;
  },
};
