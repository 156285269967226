// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { App } from "../../App";

import { Splash } from "../../Splash";
import { Catalog } from "../../Catalog/components/Catalog";
import { Details } from "../../Catalog/components/Details";
import { Booking } from "../../Booking/Booking";
import { Contact } from "../../Contact";

import { AppBottomNav } from "./AppBottomNav";
import { GlobalStyles, ThemeProvider } from "@mui/material";
import { ContextContainer } from "../context/ContextContainer";
import { Ticket } from "../../Ticket";

import { theme } from "../../theme";
import "react-calendar/dist/Calendar.css";

export const AppRouter = () => {
  return (
    <ThemeProvider theme={theme}>
      <ContextContainer>
        <BrowserRouter>
          <GlobalStyles styles={{ body: { margin: 0 } }} />
          <div
            style={{
              height: window.innerHeight,
              maxHeight: "-webkit-fill-available",
              minHeight: "-webkit-fill-available",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                flexGrow: 1,
                width: "100%",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Routes>
                <Route path="/" element={<App />}>
                  <Route index element={<Splash />} />
                  <Route path="home" element={<></>} />
                  <Route path="password/reset" element={<></>} />
                  <Route path="details/:id" element={<Details />} />
                  <Route path="bookingList/:id" element={<Booking />} />
                  <Route path="booking/:id" element={<Ticket />} />
                  <Route path="contact" element={<Contact />} />
                </Route>
              </Routes>
              {/*
                pour éviter les rendus intempestifs, la map est sortie du router.
                elle est rendue en background tout le temps, mais ne sera visible que sur '/home'
               */}
              <div style={{ position: "absolute", inset: 0, zIndex: 0 }}>
                <Catalog />
              </div>
            </div>
            <AppBottomNav />
          </div>
        </BrowserRouter>
      </ContextContainer>
    </ThemeProvider>
  );
};
