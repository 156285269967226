import { BookingStatusType } from "../../model/types";

export const bookingHelper = {
  STATUS: {
    "1": "pending",
    "2": "success",
    "3": "checkin",
    "4": "canceled",
    "5": "error",
  } as Record<string, BookingStatusType>,
  BOOKING_STATUS: {
    pending: {
      iconColor: "text.secondary",
      textColor: "text.secondary",
      text: "Réservation en attente de confirmation",
    },
    error: {
      iconColor: "error.main",
      textColor: "error.main",
      text: "Réservation refusée",
    },
    checkin: {
      iconColor: "error.main",
      textColor: "error.main",
      text: "Réservation refusée",
    },
    success: {
      iconColor: "success.main",
      textColor: "success.main",
      text: "Réservation validée",
    },
    canceled: {
      iconColor: "common.black",
      textColor: "common.black",
      text: "Réservation annulée",
    },
  },
};
