import React from "react";
import { Outlet } from "react-router-dom";

export function App(): React.ReactElement {
  return (
    <div
      style={{
        height: "100%",
        position: "relative",
        zIndex: 1,
        pointerEvents: "none",
        overflow: "hidden",
      }}
    >
      <Outlet />
    </div>
  );
}
