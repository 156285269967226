import * as React from "react";
import { Box, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

export function AppCurrencyBadge({
  variant = "h3",
  size = 24,
  small = false,
  reverse = false,
}: {
  size?: number;
  variant?: Variant;
  small?: boolean;
  reverse?: boolean;
}): React.ReactElement {
  return (
    <Box
      component={"span"}
      bgcolor={reverse ? "common.white" : "primary.main"}
      borderRadius={"50%"}
      maxHeight={size}
      maxWidth={size}
      height={small ? size / 1.5 : size}
      width={small ? size / 1.5 : size}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      boxSizing={"border-box"}
    >
      <Typography
        component={"span"}
        variant={variant}
        color={reverse ? "primary.main" : "common.white"}
        style={{
          fontSize: small ? 12 : 14,
          height: small ? 12 : 14,
          lineHeight: small ? "12px" : "14px",
        }}
      >
        €
      </Typography>
    </Box>
  );
}
