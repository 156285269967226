import * as client from "./client";
import * as storage from "../../lib/js/Storage";
import { getCached } from "../../lib/js/cache";
import { LoginResponseType, UserType } from "../Common/model/types";

export const apiAuthProvider = {
  async login(
    username: string,
    password: string,
    // eslint-disable-next-line no-unused-vars
    callback: (user: UserType) => void,
    // eslint-disable-next-line no-unused-vars
    errorCallback: (e: any) => void
  ): Promise<UserType> {
    return getCached<UserType>(
      "app.auth.user",
      async (): Promise<UserType> => {
        const data = (await client.login(
          username,
          password
        )) as LoginResponseType;
        callback(data.user);
        // pour pouvoir récupérer le token parce que si on retourne la réponse complète du login,
        // on aura un LoginResponseType dans le cache et on veut stocker les éléments séparément.
        const event = new CustomEvent("user.login", {
          detail: data,
        });
        window.dispatchEvent(event);
        return data.user;
      },
      localStorage
    )
      .then<UserType>((user) => {
        callback(user);

        return user;
      })
      .catch((e) => {
        errorCallback(e);
        return e;
      });
  },
  async reinitPassword(
    email: string,
    // eslint-disable-next-line no-unused-vars
    callback: () => void,
    // eslint-disable-next-line no-unused-vars
    errorCallback: (e: any) => void
  ): Promise<void> {
    client.reinitPassword(email).then(callback).catch(errorCallback);
  },
  async newPassword(
    email: string,
    token: string,
    password: string,
    callback: () => void,
    // eslint-disable-next-line no-unused-vars
    errorCallback: (e: any) => void
  ) {
    await client
      .newPassword(email, token, password)
      .then(callback)
      .catch(errorCallback);
  },
  logout(callback: VoidFunction) {
    storage.remove(localStorage, "app.auth.token");
    storage.remove(localStorage, "app.auth.user");
    callback();
  },
  getStoredUser(): UserType | null {
    return storage.get<UserType>(localStorage, "app.auth.user", null);
  },
  getToken(): string | null {
    return storage.get<string>(localStorage, "app.auth.token", null);
  },
  signin(email: string, plainPassword: string) {
    return client.signin(email, plainPassword);
  },
};

window.addEventListener("user.login", (event) => {
  // sera supprimé lors du logout
  storage.set(
    localStorage,
    "app.auth.token",
    (event as CustomEvent).detail.token
  );
});
