import { Slider, styled } from "@mui/material";

export const AppRangeSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-track": {
    backgroundColor: `${theme.palette.secondary.light}`,
    border: "none",
    borderRadius: 16,
    height: 32,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.grey[100],
    height: 2,
  },
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "transparent",
    border: `4px solid ${theme.palette.secondary.main}`,
    transform: "translate(8px, -50%)",
    "& .MuiSlider-valueLabel": {
      ...theme.typography.body2,
      backgroundColor: "transparent",
      color: theme.palette.secondary.main,
      height: "auto",
      transform: "unset",
      left: 4,
      "&:before": {
        content: "unset",
      },
    },

    "& + span": {
      transform: "translate(-24px, -50%)",
      "& .MuiSlider-valueLabel": {
        left: "unset",
        right: 4,
      },
    },
  },
}));
