import * as React from "react";
import { Box, Typography } from "@mui/material";
import { ServiceType } from "../model/types";

export function AppConvenienceButton({
  convenience,
  isActive,
  onClick,
}: {
  convenience: ServiceType;
  isActive: boolean;
  onClick: () => void;
}) {
  return (
    <Box
      key={convenience.key}
      role={"button"}
      onClick={onClick}
      display={"flex"}
      alignItems={"center"}
      gap={1}
      sx={(theme) => ({
        background: isActive
          ? theme.palette.secondary.light
          : theme.palette.common.white,
        color: isActive
          ? theme.palette.secondary.main
          : theme.palette.common.black,
        fill: isActive
          ? theme.palette.secondary.main
          : theme.palette.common.black,
        border: isActive
          ? `1px solid transparent`
          : `1px solid ${theme.palette.grey[100]}`,
        borderRadius: "16px",
        boxSizing: "border-box",
        cursor: "pointer",
        padding: "6px 12px",
      })}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: convenience.content
            .replace("#000000", "inherit")
            .replace(/24px/gi, "16px"),
        }}
        style={{
          height: 16,
          width: 16,
        }}
      />
      <Typography variant={"body2"} component={"span"}>
        {convenience.label}
      </Typography>
    </Box>
  );
}
