export function formatDateForApi(date: Date): string {
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0")
  );
}

export function formatDate(date: Date): string {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString(
    "fr-FR",
    options as Intl.DateTimeFormatOptions
  );
}
