import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Rating, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import { AppIcon } from "../../Common/components/AppIcon";
import { useInView } from "react-intersection-observer";
import { ServicesList } from "./ServicesList";
import { mapHelper } from "../../Common/domain/helpers/mapHelper";
import { AppCurrencyBadge } from "../../Common/components/AppCurrencyBadge";
import { AppContext } from "../../Common/context/ContextContainer";
import { BureauType, CompanyType } from "../../Common/model/types";
import { executeNinoxQuery } from "../../Common/domain/helpers/client";
import { companyHelper } from "../../Common/domain/helpers/companyHelper";

export function CompanyThumbnail({ company }: { company: CompanyType | null }) {
  const history = useNavigate();
  const { catalog, auth, mapInfos } = useContext(AppContext);
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    if (null === company) {
      return;
    }
    const companyPosition = new mapboxgl.LngLat(
      Number.parseFloat(company.entreprise.longitude),
      Number.parseFloat(company.entreprise.latitude)
    );
    let mapCenter;
    if (auth.userPosition) {
      mapCenter = new mapboxgl.LngLat(
        auth.userPosition.coords.longitude,
        auth.userPosition.coords.latitude
      );
    } else {
      mapCenter = new mapboxgl.LngLat(
        Number.parseFloat(mapHelper.MAP_CENTER.lng),
        Number.parseFloat(mapHelper.MAP_CENTER.lat)
      );
    }

    const distance = mapCenter.distanceTo(companyPosition);
    setDistance(distance);
  }, []);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [isOpen, setIsOpen] = useState(false);

  function toggleDrawer() {
    setIsOpen((isOpen) => !isOpen);
  }

  useEffect(() => {
    if (isOpen && company) {
      history(`/details/${company.entreprise.id}`);
    } else {
      history(`/home`);
    }
  }, [isOpen]);

  if (null === company || 0 === company.entreprise.bureaux.length) {
    return <></>;
  }

  function handleFavorite() {
    if (null === company || null === auth.user) {
      return;
    }

    executeNinoxQuery(
      `toggleFavorites(${auth.user.ninoxId},${company.entreprise.id})`
    )
      .then(() => {
        catalog.getUserFavorites();
      })
      .catch((e) => {
        /*@todo: toast erreur */
        console.log({ e });
      });
  }

  return (
    <>
      <Box
        ref={ref}
        sx={() => ({
          color: "inherit",
          display: "block",
          overflow: "hidden",
        })}
      >
        {inView ? (
          <Box display={"flex"} gap={1} overflow={"hidden"}>
            {company.entreprise.bureaux.map(
              (office: BureauType, index: number) => {
                if (2 < index) {
                  return;
                }
                return (
                  <Box
                    component={"picture"}
                    display={"flex"}
                    height={80}
                    width={120}
                    bgcolor={"grey.100"}
                    key={index}
                  >
                    <img
                      src={office.url}
                      alt=""
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </Box>
                );
              }
            )}
          </Box>
        ) : (
          <Box style={{ height: 80 }} />
        )}

        <Box display={"flex"} justifyContent={"space-between"} gap={2} mt={2}>
          <Typography variant={"body1"}>
            {company.entreprise.adresse}
          </Typography>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Typography
              variant={"caption"}
              color={"text.secondary"}
              style={{ whiteSpace: "pre" }}
            >
              à partir de
            </Typography>
            <Typography
              variant={"body2"}
              style={{
                whiteSpace: "pre",
                display: "flex",
                alignItems: "center",
              }}
            >
              {companyHelper.getLowerOfficeCost(company)}&nbsp;
              <AppCurrencyBadge small />
            </Typography>
          </Box>
        </Box>

        <Box display={"flex"} alignItems={"center"} mt={1} gap={0.5}>
          <Typography variant={"caption"}>
            {company.entreprise.rating.toFixed(0)}
          </Typography>
          <Rating
            name={`${company.entreprise.id}_rating`}
            value={company.entreprise.rating}
            precision={0.5}
            size={"small"}
          />
          <Typography variant={"caption"} color={"text.secondary"}>
            ({company.entreprise.reviews})
          </Typography>

          <span>·</span>
          <Typography variant={"caption"}>
            {companyHelper.getFormattedDistance(distance)}
          </Typography>
          <Typography variant={"caption"} color={"text.secondary"}>
            ({companyHelper.getWalkingTime(distance)})
          </Typography>
        </Box>

        <ServicesList services={company.entreprise.services} />

        <Box display={"flex"} gap={1} mt={2}>
          <Button
            variant={"contained"}
            color={"secondary"}
            size={"small"}
            disableElevation
            onClick={() => toggleDrawer()}
          >
            Réserver
          </Button>
          <Button
            variant={"outlined"}
            size={"small"}
            disableElevation
            onClick={() =>
              window.open(
                mapHelper.getItineraryBetweenUserAndEntreprise(
                  mapInfos.mapCoords,
                  company?.entreprise
                )
              )
            }
          >
            Itinéraire
          </Button>
          <Button
            variant={"contained"}
            size={"small"}
            disableElevation
            sx={
              undefined ===
              catalog.favoritesCompanies.find(
                (comp) => comp.entreprise.id === company.entreprise.id
              )
                ? {
                    backgroundColor: "#E7E7E7",
                    "&:hover": {
                      backgroundColor: "#E7E7E7",
                    },
                  }
                : {}
            }
            onClick={handleFavorite}
          >
            <AppIcon name={"favorite"} size={16}></AppIcon>
          </Button>
        </Box>
      </Box>
    </>
  );
}
