import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";

export function Contact(): React.ReactElement {
  return (
    <div style={{ pointerEvents: "all" }}>
      <Box p={2}>
        <Typography variant={"h1"} align={"center"}>
          Contactez-nous
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <Typography variant={"body1"} align={"center"} pt={2}>
          Vous avez des questions, remarques, propositions et souhaitez nous
          contacter ?
        </Typography>
        <Typography variant={"body1"} align={"center"} pt={2}>
          Cliquez sur le lien ci-dessous :
        </Typography>
        <Box pt={2} display={"flex"} justifyContent={"center"}>
          <a
            href={"mailto:contact@remote+.fr"}
            rel={"noreferrer noopener"}
            style={{ textDecoration: "unset" }}
          >
            <Button variant={"contained"}>Contactez-nous</Button>
          </a>
        </Box>
      </Box>
    </div>
  );
}
