import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { AppContext } from "../../Common/context/ContextContainer";
import { Alert, Box, Button, Snackbar, TextField } from "@mui/material";
import { AppPanelHeader } from "../../Common/components/AppPanelHeader";

export function ReinitPasswordForm(props: {
  from: string;
  setFormToShow: Dispatch<
    SetStateAction<"login" | "signin" | "reinitPassword" | "newPassword">
  >;
}): React.ReactElement {
  const { auth } = useContext(AppContext);
  const [formError, setFormError] = useState<{
    message: string;
  } | null>(null);
  const [formSuccess, setFormSuccess] = useState<{
    message: string;
  } | null>(null);
  const [reinitMdpForm, setReinitMdpForm] = useState<{
    email: string | null;
    password: string | null;
    passwordConfirmation: string | null;
  }>({ email: null, password: null, passwordConfirmation: null });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (null === reinitMdpForm.email) {
      return;
    }

    auth.reinitPassword(
      reinitMdpForm.email,
      () => {
        setFormSuccess({
          message: "Un email de réinitialisation vous a été envoyé",
        });
      },
      (e: { description: string }) => {
        setFormError({ message: e.description });
      }
    );
  }

  function resetError() {
    setFormError(null);
  }

  function resetSuccess() {
    setFormSuccess(null);
    props.setFormToShow("login");
  }

  function handleFieldChange(e: { target: { name: string; value: string } }) {
    setReinitMdpForm({ ...reinitMdpForm, [e.target.name]: e.target.value });
  }

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <AppPanelHeader
          title={"Réinitialisation du mot de passe"}
          onClose={() => props.setFormToShow("login")}
        />
        <Box display={"flex"} flexDirection={"column"} gap={2} pt={2}>
          <TextField
            name="email"
            label="Email"
            type="email"
            autoComplete={"off"}
            required
            focused
            onChange={handleFieldChange}
          />
          <Button
            variant={"contained"}
            type="submit"
            disabled={null === reinitMdpForm.email}
          >
            Réinitialiser mon mot de passe
          </Button>
        </Box>
      </form>
      <Snackbar
        open={null !== formError}
        autoHideDuration={6000}
        onClose={resetError}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={resetError} severity="error" sx={{ width: "100%" }}>
          {formError?.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={null !== formSuccess}
        autoHideDuration={6000}
        onClose={resetSuccess}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={resetSuccess} severity="success" sx={{ width: "100%" }}>
          {formSuccess?.message}
        </Alert>
      </Snackbar>
    </>
  );
}
