import { CompanyType } from "../../model/types";

export const companyHelper = {
  /**
   * Retourne le plus petit tarif parmi les bureaux d'une entreprise
   * @param company
   */
  getLowerOfficeCost: (company: CompanyType): string => {
    const tarifs: number[] = [];
    company.entreprise.bureaux.forEach((bureau) => {
      tarifs.push(bureau.tarif);
    });

    return Math.min(...tarifs)
      .toFixed(2)
      .toString();
  },
  /**
   * Calcule la distance entre le marker et le centre de la map
   */
  getFormattedDistance: (distance: number | null): string => {
    if (null === distance) {
      return "";
    }
    let unit = "m";

    if (1000 < distance) {
      distance = distance / 1000;
      unit = "km";
    }

    const formattedDistance = new Intl.NumberFormat("fr-FR", {
      maximumSignificantDigits: 1,
    }).format(distance);

    return `${formattedDistance} ${unit}`;
  },
  /**
   * Transforme une distance en temps de trajet
   */
  getWalkingTime: (distance: number | null): string => {
    if (null === distance) {
      return "";
    }
    const AVERAGE_PEDESTRIAN_KM_BY_HOUR = 4;
    let walkingTime = 60 * (distance / 1000 / AVERAGE_PEDESTRIAN_KM_BY_HOUR);
    let unit = "min";

    if (60 < walkingTime) {
      walkingTime = walkingTime / 60;
      unit = "h";
    }

    return `≈ ${walkingTime.toFixed(0)} ${unit}`;
  },
};
