import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import Calendar from "react-calendar";
import { uiHelper } from "../../Common/domain/helpers/uiHelper";

function isTileDisabled(props: { date: Date }) {
  return props.date.getDay() === 0;
}

export function SelectedOfficePanelCalendar({
  isModalOpen,
  handleCloseModal,
  date,
  onDateChange,
}: {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  date: Date;
  onDateChange: Dispatch<SetStateAction<Date>>;
}): React.ReactElement {
  const [value, onChange] = useState(date);

  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="popin calendar"
      aria-describedby="clendrier pour le choix du jour de réservation"
    >
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        p={2}
        sx={{ pointerEvents: "none" }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          bgcolor={"common.white"}
          p={2}
          sx={{ pointerEvents: "all" }}
        >
          <Box>
            <Typography variant={"body2"}>{value.getFullYear()}</Typography>
            <Typography variant={"h1"}>{uiHelper.formatDate(value)}</Typography>
            <Box display={"flex"} justifyContent={"center"} mt={2}>
              <Calendar
                minDate={uiHelper.getTomorrowDate()}
                onChange={onChange}
                value={value}
                tileDisabled={isTileDisabled}
              />
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"} gap={1} mt={4}>
              <Button
                variant={"text"}
                color={"inherit"}
                onClick={handleCloseModal}
              >
                Annuler
              </Button>
              <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => onDateChange(value)}
              >
                Valider
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
