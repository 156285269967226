import React, { useContext } from "react";
import { ReactNode } from "react";
import { AppContext } from "../context/ContextContainer";
import { Authentication } from "../../User/components/Authentication";

export function AppPageLayout({ children }: { children: ReactNode }) {
  const { auth } = useContext(AppContext);

  if (null === auth.user) {
    return <Authentication />;
  }

  return (
    <div style={{ pointerEvents: "all", height: "100%", overflow: "auto" }}>
      {children}
    </div>
  );
}
