import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useState,
} from "react";
import {
  FormProvider,
  UseFormReturn,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { debounce } from "../../../lib/js/debounce";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { AppIcon } from "../../Common/components/AppIcon";
import "swiper/swiper.scss";
import { SettingsSwiper } from "./SettingsSwiper";
import remoteLogo from "../../assets/images/remote_logo.png";
import { AppUserCredentials } from "../../Common/components/AppUserCredentials";
import { AppCurrencyBadge } from "../../Common/components/AppCurrencyBadge";
import { AppContext } from "../../Common/context/ContextContainer";
import { SearchQueryType } from "../../Common/model/types";
import { useNavigate } from "react-router-dom";
import { mapHelper } from "../../Common/domain/helpers/mapHelper";

type AutocompleteOption = {
  place_name: string;
  geometry: {
    coordinates: number[];
  };
};

export function SearchEngineForm({
  form,
  isResultsPanelOpen,
  handleResultsPanel,
  register,
  setValue,
  toggleSettingsDrawer,
  setMapCoordinates,
}: {
  form: UseFormReturn<SearchQueryType>;
  isResultsPanelOpen: boolean;
  handleResultsPanel: () => void;
  register: UseFormRegister<SearchQueryType>;
  setValue: UseFormSetValue<SearchQueryType>;
  toggleSettingsDrawer: () => void;
  setMapCoordinates: Dispatch<SetStateAction<number[] | null>>;
}): React.ReactElement {
  const { app, auth } = useContext(AppContext);
  const navigate = useNavigate();

  const [autocompleteOptions, setAutocompleteOptions] = useState<
    AutocompleteOption[]
  >([]);

  function handleInput(e: React.ChangeEvent<HTMLInputElement>) {
    if ("" === e.target.value) {
      return;
    }
    mapHelper.getPlacesFromString(e.target.value).then((r) => {
      setAutocompleteOptions(r.features);
    });
  }

  function handleOption(
    _e: SyntheticEvent<Element, Event>,
    value: AutocompleteOption | null
  ) {
    if (null === value) {
      return;
    }
    setValue("needle", value.place_name, { shouldDirty: true });
    setMapCoordinates(value.geometry.coordinates);
  }

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      style={{
        background: isResultsPanelOpen ? "white" : "transparent",
        padding: "16px 16px 0 16px",
        transition: "background .3s ease",
        pointerEvents: "all",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        style={{
          background: "white",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.35)",
          boxSizing: "border-box",
          minHeight: 56,
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          mx={2}
          style={{ cursor: "pointer" }}
        >
          {isResultsPanelOpen ? (
            <AppIcon name={"arrow_back"} onClick={handleResultsPanel} />
          ) : (
            <AppIcon name={"home"} onClick={() => navigate("/")} />
          )}
        </Box>

        <Box
          flexGrow={1}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          position={"relative"}
          sx={{
            opacity: !app.appIsReady ? 0 : 1,
            transition: "opacity .3s ease",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"common.white"}
            position={"absolute"}
            top={0}
            bottom={0}
            left={0}
            right={0}
            zIndex={10}
            sx={{
              opacity: app.appIsLoaded ? 0 : 1,
              pointerEvents: "none",
              transition: "all .3s ease",
            }}
          >
            <img
              src={remoteLogo}
              alt="Logo remote +"
              style={{
                transform: app.appIsLoaded ? "scale(2)" : "scale(1)",
                transition: "all .3s ease",
              }}
            />
          </Box>
          <Autocomplete
            disablePortal
            id={"remote-autocomplete"}
            getOptionLabel={(option) => option.place_name}
            isOptionEqualToValue={(option, value) =>
              option.place_name === value.place_name
            }
            options={autocompleteOptions}
            noOptionsText={"Aucun résultat pour cette recherche"}
            clearOnBlur={false}
            clearOnEscape={false}
            onInputChange={debounce((e: ChangeEvent<HTMLInputElement>) => {
              handleInput(e);
            }, 350)}
            onChange={handleOption}
            sx={{
              width: "100%",
              "& .MuiInputBase-root::before": {
                borderBottom: "unset !important",
              },
              "& .MuiInputBase-root::after": {
                borderBottom: "unset !important",
              },
            }}
            renderInput={(params) => (
              <TextField
                variant={"standard"}
                placeholder={"Votre recherche"}
                {...params}
              />
            )}
          />
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            bgcolor={"primary.main"}
            color={"common.white"}
            borderRadius={4}
            p={0.5}
          >
            <Typography variant={"caption"}>{auth.user?.solde}</Typography>
            <AppCurrencyBadge small reverse />
          </Box>
        </Box>

        <AppUserCredentials />
      </Box>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Box mr={isResultsPanelOpen ? 2 : 0}>
          <Box
            role={"button"}
            height={24}
            width={isResultsPanelOpen ? 24 : 0}
            style={{
              transition: "width .3s ease",
              overflow: "hidden",
            }}
            onClick={toggleSettingsDrawer}
          >
            <AppIcon name={"tune"} />
          </Box>
        </Box>
        <FormProvider {...form}>
          <SettingsSwiper />
        </FormProvider>
      </div>
      <input type={"hidden"} {...register("coordinates")} />
    </form>
  );
}
