import React, { useContext, useEffect } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Link, useMatch } from "react-router-dom";
import { AppIcon } from "./AppIcon";
import { useLocation } from "react-router-dom";
import { AppContext } from "../context/ContextContainer";

/**
 * Navigation sticky en bas de page
 * @constructor
 */
export const AppBottomNav = () => {
  const { auth } = useContext(AppContext);
  const [value, setValue] = React.useState("Accueil");
  const location = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(location.pathname.split("/")[1]);
  }, [location]);

  const is = {
    Home: null !== useMatch("/home"),
    Booking: null !== useMatch("/login"),
    Contact: null !== useMatch("/contact"),
  };

  /**
   * Ce composant ne s'affiche pas sur le splash screen
   */
  if ("/" === location.pathname || !auth.user) {
    return <></>;
  }

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        sx={{ borderTop: "1px solid #E7E7E7", minHeight: 81, zIndex: 1201 }}
      >
        <BottomNavigationAction
          label="Accueil"
          value="home"
          icon={<AppIcon name={"map"} filled={is.Home} />}
          component={Link}
          to="/home"
          showLabel
        />
        <BottomNavigationAction
          label="Réservations"
          value="bookingList"
          icon={<AppIcon name={"event"} filled={is.Booking} />}
          component={Link}
          to={`/bookingList/${auth.user?.id}`}
          showLabel
        />
        <BottomNavigationAction
          label="Contact"
          value="contact"
          icon={<AppIcon name={"group"} filled={is.Contact} />}
          component={Link}
          to="/contact"
          showLabel
        />
      </BottomNavigation>
    </>
  );
};
