import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Snackbar,
  Typography,
} from "@mui/material";
import { AppIcon } from "../../Common/components/AppIcon";
import { SelectedOfficePanelCalendar } from "./SelectedOfficePanelCalendar";
import { AppCurrencyBadge } from "../../Common/components/AppCurrencyBadge";
import { uiHelper } from "../../Common/domain/helpers/uiHelper";
import { ServicesList } from "./ServicesList";
import { bookOffice } from "../../Common/domain/helpers/client";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Common/context/ContextContainer";
import { BureauType } from "../../Common/model/types";

export function SelectedOfficePanel({
  selectedOffice,
  setSelectedOffice,
}: {
  selectedOffice: BureauType | null;
  setSelectedOffice: Dispatch<SetStateAction<BureauType | null>>;
}): React.ReactElement {
  const { auth, catalog } = useContext(AppContext);
  const [value, onChange] = useState<Date>(uiHelper.getTomorrowDate());
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [bookingError, setBookingError] = useState<{
    message: string;
  } | null>(null);
  const [loading, setloading] = React.useState(false);

  function resetError() {
    setBookingError(null);
  }

  useEffect(() => {
    handleClose();
  }, [value]);

  function bookThisOffice() {
    if (null === selectedOffice || null === auth.user) {
      return;
    }
    setloading(true);

    if (selectedOffice.tarif > auth.user.solde) {
      setBookingError({ message: "Votre solde n'est pas suffisant" });
      setloading(false);
      return;
    }
    bookOffice(
      selectedOffice.uuid,
      uiHelper.formatDateForBooking(value),
      auth.user.ninoxId
    )
      .then((resp) => {
        catalog.getReservations();
        navigate(`/booking/${resp.numero}`);
      })
      .catch((err) => {
        setBookingError(err);
      })
      .finally(() => {
        setloading(false);
      });
  }

  return (
    <>
      <Drawer
        anchor={"bottom"}
        open={null !== selectedOffice}
        PaperProps={{ style: { height: "100%" } }}
      >
        {null !== selectedOffice && (
          <Box position={"relative"} height={"100%"}>
            <Button
              variant={"contained"}
              color={"secondary"}
              style={{ position: "absolute", top: 40, right: 16 }}
              onClick={() => setSelectedOffice(null)}
            >
              <Box py={0.5} display={"flex"} alignItems={"center"}>
                <AppIcon name={"close"} filled={false} />
              </Box>
            </Button>

            <picture
              style={{ aspectRatio: "4/3", display: "flex", width: "100%" }}
            >
              <img
                src={selectedOffice.url360}
                alt={selectedOffice.nom}
                style={{ height: "100%", objectFit: "cover", width: "100%" }}
              />
            </picture>

            <Box p={2}>
              <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                <Typography variant={"h1"}>
                  Bureau {selectedOffice.type}
                </Typography>
                <Typography
                  variant={"body1"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  {selectedOffice.tarif.toFixed(1)}&nbsp;
                  <AppCurrencyBadge small />
                </Typography>
              </Box>
              <Typography variant={"body2"} color={"text.secondary"}>
                {selectedOffice.caracteristiques}
              </Typography>
              <ServicesList services={selectedOffice.services} />
            </Box>

            <Divider />

            <Box p={2}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={2}
              >
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <AppIcon name={"date_range"} filled={false} />
                  <Typography variant={"body2"}>
                    {uiHelper.formatDate(value)}
                  </Typography>
                </Box>
                <Typography
                  variant={"body2"}
                  style={{ textDecoration: "underline" }}
                  role={"button"}
                  onClick={handleOpen}
                >
                  Modifier
                </Typography>
              </Box>

              <SelectedOfficePanelCalendar
                date={value}
                handleCloseModal={handleClose}
                isModalOpen={open}
                onDateChange={onChange}
              />

              <Box display={"flex"} alignItems={"center"} mb={2}>
                <Box display={"flex"} gap={2} alignItems={"flex-start"}>
                  <AppIcon name={"schedule"} filled={false} />
                  <Box>
                    <Typography variant={"body2"} mb={2}>
                      Arrivée : 8h00
                    </Typography>
                    <Typography variant={"body2"}>Départ : 18h00</Typography>
                  </Box>
                </Box>
              </Box>

              <Button
                variant={"contained"}
                color={"primary"}
                fullWidth
                onClick={bookThisOffice}
              >
                Réserver pour {selectedOffice.tarif.toFixed(1)}&nbsp;&nbsp;
                <AppCurrencyBadge reverse />
              </Button>
            </Box>

            {loading && (
              <Box
                position={"absolute"}
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgcolor={"common.white"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ pointerEvents: "none" }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        )}
        <Snackbar
          open={null !== bookingError}
          autoHideDuration={6000}
          onClose={resetError}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert onClose={resetError} severity="error" sx={{ width: "100%" }}>
            {bookingError?.message}
          </Alert>
        </Snackbar>
      </Drawer>
    </>
  );
}
