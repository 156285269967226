import * as React from "react";
import { Box, Typography } from "@mui/material";
import { AppIcon } from "./AppIcon";

export function AppPanelHeader({
  title,
  onClose,
}: {
  title: string;
  onClose: () => void;
}): React.ReactElement {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      px={2}
      py={1.5}
      sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[100]}` })}
    >
      <Typography variant={"h1"}>{title}</Typography>
      <div role={"button"} onClick={onClose}>
        <AppIcon name={"close"} />
      </div>
    </Box>
  );
}
