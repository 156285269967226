import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { HydraError } from "../../lib/Hydra/hydra";
import { apiAuthProvider } from "../User/authProvider";

function withAuthConfig(): AxiosRequestConfig {
  const config: AxiosRequestConfig = {};
  const token = apiAuthProvider.getToken();
  if (null !== token) {
    config.headers = {
      "X-AUTH-TOKEN": token,
    };
  }
  return config;
}

export async function get<T>(url: string): Promise<T> {
  let response: AxiosResponse<T>;

  const config = withAuthConfig();

  if (url.startsWith(process.env.API_BASE_PATH as string)) {
    response = await axios.get<T>(
      (process.env.API_HOST as string) + url,
      config
    );
  } else {
    response = await axios.get<T>(
      (((process.env.API_HOST as string) +
        process.env.API_BASE_PATH) as string) + url,
      config
    );
  }
  return response["data"];
}

export async function post<T>(url: string, data: object): Promise<T> {
  const config = withAuthConfig();

  try {
    const response = await axios.post<T>(
      (((process.env.API_HOST as string) +
        process.env.API_BASE_PATH) as string) + url,
      data,
      config
    );

    return response["data"];
  } catch (error) {
    const response = (error as AxiosError).response?.data as HydraError;
    throw new ApiClientError(
      response["hydra:title"] ?? "Erreur interne",
      response["hydra:description"] ??
        "Une erreur est survenue. Veuillez ré-essayer ou nous contacter. Merci."
    );
  }
}

export class ApiClientError {
  public title: string;
  public description: string;
  constructor(title: string, description: string) {
    this.title = title;
    this.description = description;
  }
}

export type RequestStatus<T> = {
  state: RequestState;
  data: T | ApiClientError | null;
};

export enum RequestState {
  Todo,
  Pending,
  Success,
  Error,
}
