import {
  HydraCollectionResponse,
  HydraItemResponse,
  HydraResponse,
} from "../../../../lib/Hydra/hydra";
import * as apiClient from "../../apiClient";
import { objectToQuery } from "../../../../lib/js/searchParams";
import { getCached } from "../../../../lib/js/cache";
import {
  CompanyType,
  ConvenienceType,
  MyBookingType,
  OfficeType,
  SearchQueryType,
  UserType,
} from "../../model/types";

export interface BookingsResponse extends BookingItemResponse {
  "hydra:member": MyBookingType[];
}

export interface BookingItemResponse extends HydraItemResponse, MyBookingType {}

export interface OfficeItemResponse extends HydraItemResponse, OfficeType {}

interface OfficeCollectionResponse extends HydraCollectionResponse {
  "hydra:member": OfficeItemResponse[];
}

export interface CompanyItemResponse extends HydraItemResponse, CompanyType {}

interface LookupResponse extends HydraResponse {
  total: number;
  start: number;
  end: number;
  results: CompanyType[];
}

interface ConvenienceItemResponse extends HydraItemResponse, ConvenienceType {}

interface ConvenienceCollectionResponse extends HydraCollectionResponse {
  "hydra:member": ConvenienceItemResponse[];
}

export async function search(values: SearchQueryType): Promise<LookupResponse> {
  const query = objectToQuery(values);

  return await apiClient.get<LookupResponse>(`/lookup?${query}`);
}

export async function getConveniences(): Promise<ConvenienceCollectionResponse> {
  return await apiClient.get<ConvenienceCollectionResponse>("/conveniences");
}

export async function getOneCompany(
  idOrIri: string
): Promise<CompanyItemResponse> {
  if (idOrIri.startsWith("/")) {
    idOrIri = idOrIri.slice(idOrIri.lastIndexOf("/") + 1);
  }
  return getCached<CompanyItemResponse>(
    `app.catalog.cache.company.${idOrIri}`,
    async () => {
      return await apiClient.get<CompanyItemResponse>(
        `/entreprises/${idOrIri}`
      );
    }
  );
}

export async function getOneOffice(id: string): Promise<OfficeItemResponse> {
  return getCached<OfficeItemResponse>(
    `app.catalog.cache.office.${id}`,
    async () => await apiClient.get<OfficeItemResponse>(`/offices/${id}`)
  );
}

export async function getOfficesForCompany(
  id: string
): Promise<OfficeCollectionResponse> {
  return getCached<OfficeCollectionResponse>(
    `app.catalog.cache.offices_for_company.${id}`,
    async () => {
      return await apiClient.get<OfficeCollectionResponse>(
        `/offices?company=${id}`
      );
    }
  );
}

/**
 * @param id => Ce n'est pas l'id, c'est le numéro, ie. L4SG-9PHL
 */
export async function getOneBooking(id: string): Promise<BookingItemResponse> {
  return apiClient.get<BookingItemResponse>(`/reservations/${id}`);
}

/**
 *
 * @param beneficiaireId => ninoxId
 * @param start
 * @param end
 */
export async function getBookingsForBeneficiaire(
  beneficiaireId: number,
  start = 0,
  end = 50
): Promise<BookingsResponse> {
  return apiClient.get<BookingsResponse>(
    `/reservations/for_beneficiaire/${beneficiaireId}?start=${start}&end=${end}`
  );
}

export async function submit(
  booking: MyBookingType
): Promise<BookingItemResponse> {
  return await apiClient.post<BookingItemResponse>("/bookings", booking);
}

export async function isOfficeAvailable(
  id: string,
  date: string
): Promise<boolean> {
  try {
    await apiClient.get<boolean>(`/bookings/office/${id}/${date}/is_available`);
    return Promise.resolve(true);
  } catch (error) {
    return Promise.resolve(false);
  }
}

export async function getUserById(id = 1): Promise<UserType> {
  return await apiClient.get<UserType>("/beneficiaires/" + id);
}
export async function bookOffice(
  officeUuid: string,
  date: string,
  userId: number
): Promise<MyBookingType> {
  const query = {
    bureauId: officeUuid,
    userId: userId,
    arrivee: date,
    type: 1,
    duree: 1,
    heures: 8,
  };
  return await apiClient.post("/reservation/create", query);
}

export function cancelBooking(
  bookingNumero: string
): Promise<BookingItemResponse> {
  return apiClient.post(`/reservation/${bookingNumero}/cancel`, {});
}

export async function executeNinoxQuery(query: string): Promise<unknown> {
  return await apiClient.get<unknown>("/ninox/proxy/" + query);
}
