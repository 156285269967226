import React, { useContext, useEffect, useState } from "react";
import QrCode from "../assets/images/qr-code_placeholder.png";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import { AppIcon } from "../Common/components/AppIcon";
import { useNavigate, useParams } from "react-router-dom";
import * as client from "../Common/domain/helpers/client";
import { AppPageLayout } from "../Common/components/AppPageLayout";
import { bookingHelper } from "../Common/domain/helpers/bookingHelper";
import { AppCurrencyBadge } from "../Common/components/AppCurrencyBadge";
import { uiHelper } from "../Common/domain/helpers/uiHelper";
import {
  BookingItemResponse,
  cancelBooking,
  executeNinoxQuery,
} from "../Common/domain/helpers/client";
import { BookingStatusType } from "../Common/model/types";
import { AppContext } from "../Common/context/ContextContainer";
import { mapHelper } from "../Common/domain/helpers/mapHelper";

/**
 * La page de confirmation de réservation
 * @constructor
 */
export function Ticket() {
  const { mapInfos, catalog, auth } = useContext(AppContext);
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [booking, setBooking] = useState<BookingItemResponse | null>(null);
  const [bookingFetchError, setBookingFetchError] = useState<{
    message: string;
  } | null>(null);
  const [bookingStatus, setBookingStatus] =
    useState<BookingStatusType>("pending");

  useEffect(() => {
    if (null === booking) {
      return;
    }
    setBookingStatus(bookingHelper.STATUS[booking.statut]);
  }, [booking]);

  function goBack() {
    navigate(-1);
  }

  function resetError() {
    setBookingFetchError(null);
  }

  function getBookingInfos() {
    if (undefined === params.id) {
      return;
    }
    resetError();
    client
      .getOneBooking(params.id)
      .then((data) => {
        setBooking(data);
      })
      .catch((err) => {
        setBookingFetchError(err);
      })
      .finally(() => setLoading(false));
  }

  useEffect(getBookingInfos, []);

  function reloadStatus() {
    setLoading(true);
    getBookingInfos();
  }

  function cancelThisBooking() {
    if (null === booking) {
      return;
    }
    setLoading(true);
    cancelBooking(booking.numero)
      .then((resp) => {
        setBooking(resp);
        catalog.getReservations();
        executeNinoxQuery(`getBeneficiaire(${auth.user?.ninoxId})`).then(
          (response: any) => {
            console.log({ response });
            auth.setUser({ ...auth.user, ...response.data.user });
          }
        );
      })
      .catch((err) => {
        setBookingFetchError({ message: err.description });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (null === booking) {
    return <></>;
  }

  return (
    <AppPageLayout>
      <Box height={"100%"} overflow={"auto"}>
        <Box
          p={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <span role={"button"} onClick={goBack}>
            <AppIcon name={"arrow_back"} filled={false} />
          </span>
          <Typography variant={"h1"} align={"center"}>
            {booking.entreprise?.nom}
          </Typography>
          <AppIcon name={"more_vert"} filled={false} />
        </Box>
        <Divider />
        {loading ? (
          <Box display={"flex"} justifyContent={"center"} py={8} px={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Box py={4} px={2}>
            <Box display={"flex"} gap={2} mb={2}>
              <AppIcon
                name={"beenhere"}
                filled={false}
                color={bookingHelper.BOOKING_STATUS[bookingStatus].iconColor}
              ></AppIcon>
              <Typography
                color={bookingHelper.BOOKING_STATUS[bookingStatus].textColor}
              >
                {bookingHelper.BOOKING_STATUS[bookingStatus].text}
              </Typography>
            </Box>
            <Box
              component={"figure"}
              height={248}
              width={248}
              my={0}
              mx={"auto"}
              pb={4}
            >
              <img src={QrCode} alt="" />
            </Box>
            <Box>
              <Box display={"flex"} gap={2} mb={2}>
                <AppIcon name={"map"} filled={false}></AppIcon>
                <Box>
                  <Typography mb={2}>{booking.entreprise?.nom}</Typography>
                  <Button
                    size={"small"}
                    variant={"outlined"}
                    onClick={() =>
                      window.open(
                        mapHelper.getItineraryBetweenUserAndEntreprise(
                          mapInfos.mapCoords,
                          booking?.entreprise
                        )
                      )
                    }
                  >
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <AppIcon name={"directions"} size={16}></AppIcon>
                      <span>itinéraire</span>
                    </Box>
                  </Button>
                </Box>
              </Box>

              <Box display={"flex"} gap={2} mb={2}>
                <AppIcon name={"desk"} filled={false}></AppIcon>
                <Box display={"flex"} alignItems={"center"} gap={0.5}>
                  <Typography>
                    Bureau {booking.bureau?.type}&nbsp;-&nbsp;
                    {booking.bureau?.tarif.toFixed(2)}
                  </Typography>
                  <AppCurrencyBadge />
                </Box>
              </Box>

              <Box display={"flex"} gap={2} mb={2}>
                <AppIcon name={"date_range"} filled={false}></AppIcon>
                <Typography>
                  {uiHelper.formatDate(new Date(booking.arriveeUTC), true)}
                </Typography>
              </Box>

              <Box display={"flex"} gap={2} mb={2}>
                <AppIcon name={"schedule"} filled={false}></AppIcon>
                <Typography>9h00 - 18h00</Typography>
              </Box>

              {"success" !== bookingStatus && (
                <Box mb={2}>
                  <Button
                    variant={"contained"}
                    fullWidth
                    onClick={reloadStatus}
                  >
                    Vérifier la statut de la réservation
                  </Button>
                </Box>
              )}

              {"canceled" !== bookingStatus && (
                <Box>
                  <Button
                    variant={"contained"}
                    color={"secondary"}
                    fullWidth
                    onClick={cancelThisBooking}
                  >
                    Annuler ma réservation
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Snackbar
          open={null !== bookingFetchError}
          autoHideDuration={6000}
          onClose={resetError}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert onClose={resetError} severity="error" sx={{ width: "100%" }}>
            {bookingFetchError?.message}
          </Alert>
        </Snackbar>
      </Box>
    </AppPageLayout>
  );
}
