import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { LoginForm } from "./LoginForm";
import { SigninForm } from "./SigninForm";
import { ReinitPasswordForm } from "./ReinitPasswordForm";
import { useLocation } from "react-router-dom";
import { NewPasswordForm } from "./NewPasswordForm";

export function Authentication() {
  const [formToShow, setFormToShow] = useState<
    "login" | "signin" | "reinitPassword" | "newPassword"
  >("login");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("password/reset")) {
      setFormToShow("newPassword");
    } else {
      setFormToShow("login");
    }
  }, [location]);

  if ("newPassword" === formToShow) {
    return (
      <Box p={2}>
        <NewPasswordForm
          from={"/home"}
          setFormToShow={setFormToShow}
          token={new URLSearchParams(location.search).get("token")}
        />
      </Box>
    );
  }

  if ("login" === formToShow) {
    return (
      <Box p={2}>
        <LoginForm from={"/home"} setFormToShow={setFormToShow} />
        <Typography variant={"body2"} align={"center"} mt={2}>
          Pas encore de compte ? <br />
          Inscrivez-vous en&nbsp;
          <span
            role={"button"}
            style={{ textDecoration: "underline" }}
            onClick={() => setFormToShow("signin")}
          >
            cliquant ici
          </span>
        </Typography>
      </Box>
    );
  }

  if ("reinitPassword" === formToShow) {
    return (
      <Box p={2}>
        <ReinitPasswordForm from={"/home"} setFormToShow={setFormToShow} />
      </Box>
    );
  }

  return (
    <Box p={2}>
      <SigninForm toggleForm={() => setFormToShow("login")} />
      <Typography variant={"body2"} align={"center"} mt={2}>
        Vous avez déjà un compte ? <br />
        Connectez-vous en&nbsp;
        <span
          role={"button"}
          style={{ textDecoration: "underline" }}
          onClick={() => setFormToShow("login")}
        >
          cliquant ici
        </span>
      </Typography>
    </Box>
  );
}
