import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function useGeolocation(): [
  GeolocationPosition | null,
  Dispatch<SetStateAction<GeolocationPosition | null>>
] {
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  function successCallback(position: GeolocationPosition) {
    setPosition(position);
  }

  function errorCallback(error: GeolocationPositionError) {
    /*@todo: toast error*/
    console.log(error);
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, []);

  return [position, setPosition];
}
