import * as React from "react";
import { ReactNode } from "react";
import { Box } from "@mui/material";

export function AppSettingsGroup({
  children,
}: {
  children: ReactNode;
}): React.ReactElement {
  return (
    <Box
      mb={3}
      display={"flex"}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: "16px",
        width: "min-content",
      })}
    >
      {children}
    </Box>
  );
}
