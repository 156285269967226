import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import { AppIcon } from "../../Common/components/AppIcon";
import { useFormContext, useWatch } from "react-hook-form";
import {
  DATE_FIELDS_OPTIONS,
  DISTANCE_FIELDS_OPTIONS,
  formValuesToSearchQuery,
  NOTE_FIELDS_OPTIONS,
} from "../../Common/domain/helpers/formHelper";
import { AppContext } from "../../Common/context/ContextContainer";
import { AppFilterButton } from "../../Common/components/AppFilterButton";
import { AppConvenienceButton } from "../../Common/components/AppConvenienceButton";
import { AppRangeSlider } from "../../Common/components/AppRangeSlider";
import { AppCurrencyBadge } from "../../Common/components/AppCurrencyBadge";
import { AppSettingsGroup } from "../../Common/components/AppSettingsGroup";
import { AppPanelHeader } from "../../Common/components/AppPanelHeader";
import { SERVICES } from "../../Common/domain/helpers/serviceshelper";
import { SearchQueryType, ServiceType } from "../../Common/model/types";

const DEFAULT_TEMPORARY_VALUES = {
  coordinates: undefined,
  date: undefined,
  radius: undefined,
  note: undefined,
  conveniences: [],
  cost: [0, 10],
};

/**
 * Le drawer contenant le panneau de filtres complet
 * @param isSettingsDrawerOpen
 * @param toggleSettingsDrawer
 * @constructor
 */
export function SettingsPanel({
  isSettingsDrawerOpen,
  toggleSettingsDrawer,
}: {
  isSettingsDrawerOpen: boolean;
  toggleSettingsDrawer: () => void;
}): React.ReactElement {
  const { catalog } = useContext(AppContext);
  const { watch, control, reset, register } = useFormContext();
  const watchAll = useWatch({ control });
  const [temporaryValues, setTemporaryValues] = useState<{
    coordinates: string | undefined;
    date: string | undefined;
    radius: number | undefined;
    note: string | undefined;
    conveniences: string[];
    cost: number[] | undefined;
  }>(DEFAULT_TEMPORARY_VALUES);

  useEffect(() => {
    setTemporaryValues({
      coordinates: watch("coordinates"),
      date: watch("date"),
      radius: watch("radius"),
      note: watch("note"),
      conveniences: watch("conveniences"),
      cost: watch("cost"),
    });
  }, [watchAll]);

  function submit() {
    reset(temporaryValues);
    catalog.setSearchQuery(
      formValuesToSearchQuery(temporaryValues as unknown as SearchQueryType)
    );
    toggleSettingsDrawer();
  }

  function resetSettings() {
    reset(DEFAULT_TEMPORARY_VALUES);
  }

  const [rangeValue, setRangeValue] = React.useState<number[]>([0, 100]);

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    setRangeValue(newValue as number[]);
    setTemporaryValues((temp) => ({
      ...temp,
      cost: newValue as number[],
    }));
  };

  const handleConvenienceChange = (service: ServiceType) => {
    if (undefined === temporaryValues["conveniences"]) {
      setTemporaryValues((temp) => ({
        ...temp,
        conveniences: [service.label],
      }));
      return;
    }
    if (
      temporaryValues["conveniences"] &&
      temporaryValues["conveniences"].includes(service.label)
    ) {
      temporaryValues["conveniences"].splice(
        temporaryValues["conveniences"].indexOf(service.label),
        1
      );
      setTemporaryValues((temp) => ({
        ...temp,
        conveniences: [...temp.conveniences],
      }));
    } else {
      setTemporaryValues((temp) => ({
        ...temp,
        conveniences: [...temp.conveniences, service.label],
      }));
    }
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={isSettingsDrawerOpen}
      onClose={toggleSettingsDrawer}
      PaperProps={{
        style: {
          height: "100%",
        },
      }}
      sx={{
        zIndex: 1202,
      }}
    >
      <Box display={"flex"} flexDirection={"column"} sx={{ height: "100%" }}>
        <AppPanelHeader title={"Filtres"} onClose={toggleSettingsDrawer} />

        <Box flexGrow={1} p={2} sx={{ overflow: "auto" }}>
          {/*Disponibilité*/}
          <Box display={"flex"} gap={1} mb={2}>
            <AppIcon name={"schedule"} filled={false} />
            <Typography variant={"h3"}>Disponibilité</Typography>
          </Box>
          <AppSettingsGroup>
            {Object.entries(DATE_FIELDS_OPTIONS).map(([key, values]) => (
              <AppFilterButton
                key={key}
                isActive={key === temporaryValues["date"]}
                label={values}
                onClick={() =>
                  setTemporaryValues((temp) => ({ ...temp, date: key }))
                }
              />
            ))}
          </AppSettingsGroup>

          {/*Distance*/}
          <Box display={"flex"} gap={1} mb={2}>
            <AppIcon name={"directions_walk"} />
            <Typography variant={"h3"}>Distance</Typography>
          </Box>
          <AppSettingsGroup>
            {DISTANCE_FIELDS_OPTIONS.map((item) => (
              <AppFilterButton
                key={item.key}
                isActive={item.key === temporaryValues["radius"]}
                label={item.value}
                onClick={() =>
                  setTemporaryValues((temp) => ({
                    ...temp,
                    radius: item.key,
                  }))
                }
              />
            ))}
          </AppSettingsGroup>

          {/*Note*/}
          <Box display={"flex"} gap={1} mb={2}>
            <AppIcon name={"hotel_class"} filled={false} />
            <Typography variant={"h3"}>Note</Typography>
          </Box>
          <AppSettingsGroup>
            {Object.entries(NOTE_FIELDS_OPTIONS).map(([key, values]) => (
              <AppFilterButton
                key={key}
                isActive={key === temporaryValues["note"]}
                label={values}
                onClick={() =>
                  setTemporaryValues((temp) => ({ ...temp, note: key }))
                }
              />
            ))}
          </AppSettingsGroup>

          {/*Coût*/}
          <Box display={"flex"} justifyContent={"space-between"} mb={2}>
            <Box display={"flex"} gap={1}>
              <AppIcon name={"toll"} filled={false} />
              <Typography variant={"h3"}>Coût</Typography>
            </Box>
            <AppCurrencyBadge />
          </Box>
          <Box mb={3}>
            <input type="hidden" {...register("cost")} />
            <AppRangeSlider
              min={0}
              max={100}
              step={10}
              color={"secondary"}
              getAriaLabel={() => "Cost range"}
              value={rangeValue}
              onChange={handleRangeChange}
              valueLabelDisplay="on"
              getAriaValueText={(value) => `${value} €`}
            />
          </Box>

          {/*Services*/}
          <Box display={"flex"} gap={1} mb={2}>
            <AppIcon name={"settings"} filled={false} />
            <Typography variant={"h3"}>Services</Typography>
          </Box>
          <Box display={"flex"} flexWrap={"wrap"} gap={1}>
            {Object.entries(SERVICES).map(([key, service]) => (
              <AppConvenienceButton
                key={key}
                convenience={service}
                isActive={
                  temporaryValues["conveniences"] &&
                  temporaryValues["conveniences"].includes(service.label)
                }
                onClick={() => handleConvenienceChange(service)}
              />
            ))}
          </Box>
        </Box>

        <Box
          display={"flex"}
          gap={2}
          p={2}
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.grey[100]}`,
          })}
        >
          <Button fullWidth variant={"outlined"} onClick={resetSettings}>
            Effacer
          </Button>
          <Button
            fullWidth
            variant={"contained"}
            color={"secondary"}
            onClick={submit}
          >
            Appliquer
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
