/* eslint-disable @typescript-eslint/no-explicit-any */
import { NonNegativeInteger } from "../../src/global";

export function debounce<T extends number>(
  // eslint-disable-next-line no-unused-vars
  callback: (...args: any[]) => void,
  wait: NonNegativeInteger<T>
) {
  let timeoutId: number;
  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
}
