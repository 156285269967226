import React, { useState } from "react";
import Map from "./Map";
import { SearchEngine } from "./SearchEngine";
import { AppPageLayout } from "../../Common/components/AppPageLayout";
import { CompanyType } from "../../Common/model/types";
import { useLocation } from "react-router-dom";

export function Catalog(): React.ReactElement {
  const location = useLocation();
  const [selectedCompany, setSelectedCompany] = useState<CompanyType | null>(
    null
  );

  const [mapCoordinates, setMapCoordinates] = useState<number[] | null>(null);

  return (
    <AppPageLayout>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          visibility: location.pathname === "/home" ? "visible" : "hidden",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <div style={{ flexGrow: 1, position: "relative" }}>
            <Map
              setSelectedCompany={setSelectedCompany}
              mapCoordinates={mapCoordinates}
            />
            <SearchEngine
              selectedCompany={selectedCompany}
              setMapCoordinates={setMapCoordinates}
            />
          </div>
        </div>
      </div>
    </AppPageLayout>
  );
}
