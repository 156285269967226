import React from "react";
import { Box, Fab } from "@mui/material";
import { AppIcon } from "./AppIcon";

export function AppSettingsCta({
  toggleSettingsDrawer,
}: {
  toggleSettingsDrawer: () => void;
}): React.ReactElement {
  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      px={2}
      style={{ pointerEvents: "all" }}
    >
      <Fab
        color="secondary"
        aria-label="Filtres"
        onClick={toggleSettingsDrawer}
        sx={{ boxShadow: "unset", zIndex: 0 }}
      >
        <AppIcon name={"tune"} />
      </Fab>
    </Box>
  );
}
